
    import './styles.scoped.css';
    export default {
  "drawer": "awsui_drawer_1r9lg_w2j5u_293",
  "refresh": "awsui_refresh_1r9lg_w2j5u_293",
  "animating": "awsui_animating_1r9lg_w2j5u_307",
  "preference-icon--layout-border": "awsui_preference-icon--layout-border_1r9lg_w2j5u_330",
  "preference-icon--layout-background": "awsui_preference-icon--layout-background_1r9lg_w2j5u_334",
  "preference-icon--layout-header": "awsui_preference-icon--layout-header_1r9lg_w2j5u_337",
  "preference-icon--border": "awsui_preference-icon--border_1r9lg_w2j5u_340",
  "preference-icon--primary-button": "awsui_preference-icon--primary-button_1r9lg_w2j5u_344",
  "preference-icon--secondary": "awsui_preference-icon--secondary_1r9lg_w2j5u_347",
  "preference-icon--disabled-element": "awsui_preference-icon--disabled-element_1r9lg_w2j5u_350",
  "preference-icon--separator": "awsui_preference-icon--separator_1r9lg_w2j5u_353",
  "preference-icon--focus-text": "awsui_preference-icon--focus-text_1r9lg_w2j5u_356",
  "preference-icon-refresh--layout-top": "awsui_preference-icon-refresh--layout-top_1r9lg_w2j5u_360",
  "preference-icon-refresh--layout-main": "awsui_preference-icon-refresh--layout-main_1r9lg_w2j5u_363",
  "preference-icon-refresh--primary": "awsui_preference-icon-refresh--primary_1r9lg_w2j5u_366",
  "preference-icon-refresh--disabled": "awsui_preference-icon-refresh--disabled_1r9lg_w2j5u_369",
  "preference-icon-refresh--column-header": "awsui_preference-icon-refresh--column-header_1r9lg_w2j5u_372",
  "preference-icon-refresh--window": "awsui_preference-icon-refresh--window_1r9lg_w2j5u_375",
  "preference-icon-refresh--secondary": "awsui_preference-icon-refresh--secondary_1r9lg_w2j5u_379",
  "preference-icon-refresh--default": "awsui_preference-icon-refresh--default_1r9lg_w2j5u_382",
  "preference-icon-refresh--separator": "awsui_preference-icon-refresh--separator_1r9lg_w2j5u_385",
  "preference-icon-refresh--input-default": "awsui_preference-icon-refresh--input-default_1r9lg_w2j5u_388",
  "preference-icon-refresh--heading": "awsui_preference-icon-refresh--heading_1r9lg_w2j5u_391",
  "root": "awsui_root_1r9lg_w2j5u_395",
  "preferences-button": "awsui_preferences-button_1r9lg_w2j5u_430",
  "close-button": "awsui_close-button_1r9lg_w2j5u_434",
  "drawer-closed": "awsui_drawer-closed_1r9lg_w2j5u_446",
  "drawer-content-side": "awsui_drawer-content-side_1r9lg_w2j5u_451",
  "drawer-content-bottom": "awsui_drawer-content-bottom_1r9lg_w2j5u_467",
  "position-bottom": "awsui_position-bottom_1r9lg_w2j5u_473",
  "position-side": "awsui_position-side_1r9lg_w2j5u_500",
  "slider": "awsui_slider_1r9lg_w2j5u_506",
  "slider-side": "awsui_slider-side_1r9lg_w2j5u_541",
  "slider-wrapper-bottom": "awsui_slider-wrapper-bottom_1r9lg_w2j5u_548",
  "slider-wrapper-side": "awsui_slider-wrapper-side_1r9lg_w2j5u_559",
  "open-button": "awsui_open-button_1r9lg_w2j5u_570",
  "open-button-side": "awsui_open-button-side_1r9lg_w2j5u_574",
  "slider-icon": "awsui_slider-icon_1r9lg_w2j5u_583",
  "slider-icon-bottom": "awsui_slider-icon-bottom_1r9lg_w2j5u_589",
  "slider-icon-side": "awsui_slider-icon-side_1r9lg_w2j5u_593",
  "pane-header-wrapper-bottom": "awsui_pane-header-wrapper-bottom_1r9lg_w2j5u_597",
  "drawer-mobile": "awsui_drawer-mobile_1r9lg_w2j5u_608",
  "drawer-disable-content-paddings": "awsui_drawer-disable-content-paddings_1r9lg_w2j5u_612",
  "content-bottom": "awsui_content-bottom_1r9lg_w2j5u_621",
  "pane-bottom-center-align": "awsui_pane-bottom-center-align_1r9lg_w2j5u_637",
  "pane-bottom-content-nav-padding": "awsui_pane-bottom-content-nav-padding_1r9lg_w2j5u_642",
  "pane-bottom-content-tools-padding": "awsui_pane-bottom-content-tools-padding_1r9lg_w2j5u_646",
  "content-bottom-max-width": "awsui_content-bottom-max-width_1r9lg_w2j5u_650",
  "content-side": "awsui_content-side_1r9lg_w2j5u_655",
  "pane-header-wrapper-side": "awsui_pane-header-wrapper-side_1r9lg_w2j5u_665",
  "pane-content-wrapper-side": "awsui_pane-content-wrapper-side_1r9lg_w2j5u_671",
  "header": "awsui_header_1r9lg_w2j5u_678",
  "header-text": "awsui_header-text_1r9lg_w2j5u_688",
  "header-actions": "awsui_header-actions_1r9lg_w2j5u_701",
  "divider": "awsui_divider_1r9lg_w2j5u_709"
};
  