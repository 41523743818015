import React, { useState, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { AppContext } from './../App'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBadgeGroup } from '../common/RadBadgeGroup'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { getDownloadUrl } from '../common/utilities'
import { Mio } from '../mio/MIO'
import { usePost } from '../hooks/usePost'

export function InsightDetail () {
  const navigate = useNavigate()
  const { insightId } = useParams()
  const { user } = useContext(AppContext)
  const [messageIn, setMessageIn] = useState({ output: 'Hi, I\'m MIO. How can I help you?' })
  const [messageOut, setMessageOut] = useState()
  const { data: insight } = useGet(`/api/insight/${insightId}`, true)
  const confirmDelete = useConfirm('Delete insight?', 'Delete insight permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/insight/${insightId}`, () => { navigate('/insight') })
  const thumbsUp = usePost(`/api/insight/${insightId}/thumbs-up`, { insightId, messageOut, messageIn }, () => { })
  const thumbsDown = usePost(`/api/insight/${insightId}/thumbs-down`, { insightId, messageOut, messageIn }, () => { })

  async function onEnter (detail) {
    setMessageIn(null)
    setMessageOut(detail.text)
    const token = await user.getIdToken()
    const response = await fetch('/api/chat', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify({ insightId, text: detail.text })
    })
    const json = await response.json()
    setMessageIn(json)
  }

  if (
    insight != null
  ) {
    return (
      <RadAppLayout
        name={insight.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={insight.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
            description={insight.description}
          >
            {insight.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadColumnLayout columns={4} borders='vertical'>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Access Type
                  </RadBox>
                  <div>{insight.accessType?.name ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Location
                  </RadBox>
                  <div>{insight.location ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Report File
                  </RadBox>
                  {insight.reportFile != null ? <RadLink href={getDownloadUrl('insight', insight.id, insight.reportFile.path)} target='_blank'>{insight.reportFile.name}</RadLink> : '-'}
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Data Products
                  </RadBox>
                  <div>{insight.dataProducts.length > 0
                    ? insight.dataProducts.map((x) => <div key={x.id}><RadLink href={`/data-product/${x.dataProduct.id}`}>{x.dataProduct.name}</RadLink></div>)
                    : '-'}
                  </div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Data Producers
                  </RadBox>
                  <div>{insight.dataProducers.length > 0
                    ? insight.dataProducers.map((x) => <div key={x.id}><RadLink href={`/${x.type}/${x.id}`}>{x.name}</RadLink></div>)
                    : '-'}
                  </div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Data Stewards
                  </RadBox>
                  <div>{insight.dataStewards.length > 0
                    ? insight.dataStewards.map((x) => <div key={x.id}><RadLink href={`/${x.type}/${x.id}`}>{x.name}</RadLink></div>)
                    : '-'}
                  </div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Partners
                  </RadBox>
                  <div>{insight.partners.length > 0
                    ? insight.partners.map((x) =>
                      <div key={x.id}><RadLink href={`/${x.collaborative != null ? 'collaborative' : ''}${x.organization != null ? 'organization' : ''}/${x.collaborativeId || x.organizationId}`}>{x.collaborative?.name || x.organization?.name}</RadLink></div>)
                    : '-'}
                  </div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Governance Indicators
                  </RadBox>
                  <RadBadgeGroup
                    parentKey={insightId}
                    items={insight.governanceIndicators}
                    mapFunc={x => ({ key: x.id, name: x.type.name })}
                  />
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Tags
                  </RadBox>
                  <RadBadgeGroup
                    parentKey={insight}
                    items={insight.tags}
                    mapFunc={x => ({ key: x.id, name: x.tag.name })}
                  />
                </div>
              </RadColumnLayout>
            </RadContainer>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  MIO
                </RadHeader>
              }
            >
              <Mio
                onEnter={onEnter}
                messageIn={messageIn}
                messageOut={messageOut}
                suggestions={insight.suggestedQuestions.map(x => x.question)}
                thumbsUp={thumbsUp}
                thumbsDown={thumbsDown}
              />
            </RadContainer>
          </RadSpaceBetween>
        }
      />
    )
  }
}
