
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_1612d_16mjo_181",
  "spinner-rotator": "awsui_spinner-rotator_1612d_16mjo_1",
  "size-normal": "awsui_size-normal_1612d_16mjo_195",
  "size-big": "awsui_size-big_1612d_16mjo_203",
  "size-large": "awsui_size-large_1612d_16mjo_211",
  "variant-normal": "awsui_variant-normal_1612d_16mjo_219",
  "variant-disabled": "awsui_variant-disabled_1612d_16mjo_222",
  "variant-inverted": "awsui_variant-inverted_1612d_16mjo_225",
  "circle": "awsui_circle_1612d_16mjo_237",
  "circle-left": "awsui_circle-left_1612d_16mjo_265",
  "spinner-line-left": "awsui_spinner-line-left_1612d_16mjo_1",
  "circle-right": "awsui_circle-right_1612d_16mjo_270",
  "spinner-line-right": "awsui_spinner-line-right_1612d_16mjo_1"
};
  