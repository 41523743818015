import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useIsMobile } from '../hooks/useIsMobile'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from './../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadAutosuggest } from '../common/RadAutosuggest'
import { RadButton } from '../common/RadButton'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'
import { stateList } from '../common/stateList'

export function OrganizationEdit () {
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const { organizationId } = useParams()
  const [formValues, setFormValues] = useState()
  // const [targetAreaFilteringText, setTargetAreaFilteringText] = useState('')
  // const [serviceFilteringText, setServiceFilteringText] = useState('')
  const { data: organization } = useGet(organizationId ? `/api/organization/${organizationId}` : null)
  const { data: typeOptions } = useGet('/api/option/type?entity=organization&required=true')
  const { data: sectorOptions } = useGet('/api/option/sector')
  // const { data: serviceOptions } = useGet(`/api/option/service?search=${serviceFilteringText}`)
  const { data: personOptions } = useGet('/api/option/person')
  // const { data: software } = useGet('/api/software')
  const { data: titleOptions } = useGet('/api/option/type?entity=title')
  // const { data: targetAreaOptions } = useGet(`/api/option/targetArea?search=${targetAreaFilteringText}`)
  // const { data: targetPopulations } = useGet('/api/targetPopulation')
  const { data: tags } = useGet('/api/tag')
  const stateListOptions = [...stateList]
  stateListOptions.unshift({ value: null, label: '-' })

  useEffect(() => {
    const defaultFormValues = { users: [], sectors: [], people: [], contacts: [], services: [], software: [], targetAreas: [], targetPopulations: [], tags: [] }
    setFormValues(organization ?? defaultFormValues)
  }, [organization])

  const create = usePost('/api/organization', formValues, (resp) => { navigate(`/organization/${resp.id}`) })
  const update = usePut(`/api/organization/${organizationId}`, formValues, (resp) => { navigate(`/organization/${organizationId}`) })

  async function saveChanges () {
    if (organizationId != null) { update() } else { create() }
  }

  async function cancel () {
    if (organizationId != null) {
      navigate(`/organization/${organizationId}`)
    } else {
      navigate('/organization')
    }
  }

  if (
    formValues != null &&
    typeOptions != null &&
    personOptions != null &&
    titleOptions != null &&
    sectorOptions != null &&
    // serviceOptions != null &&
    // software != null &&
    // targetAreaOptions != null &&
    // targetPopulations != null &&
    tags != null
  ) {
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>
            {formValues.id != null ? 'Edit Organization' : 'New Organization'}
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Name *' field='name'>
                      <RadInput
                        placeholder='Enter name'
                        ariaRequired
                        value={formValues.name}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                        disabled={formValues.isProtected}
                      />
                    </RadFormField>
                    <RadFormField label='Type *' field='typeId'>
                      <RadSelect
                        selectedOption={formValues.typeId ? typeOptions.find(x => x.value === formValues.typeId?.toString()) : null}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, typeId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, typeId: null })
                          }
                        }}
                        options={typeOptions}
                        selectedAriaLabel='Selected'
                        placeholder='Choose a type'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Description' field='description'>
                      <RadTextarea
                        placeholder='Enter description'
                        value={formValues.description}
                        onChange={({ detail }) => setFormValues({ ...formValues, description: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Website' field='website'>
                      <RadInput
                        type='url'
                        inputMode='url'
                        placeholder='Enter website url (https://www.example.com)'
                        value={formValues.website}
                        onChange={({ detail }) => setFormValues({ ...formValues, website: detail.value })}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Address
                    </RadHeader>
                  }
                >
                  <RadGrid
                    gridDefinition={[
                      { colspan: { default: 12, xxs: 6 } },
                      { colspan: { default: 12, xxs: 6 } }
                    ]}
                  >
                    <RadFormField label='Address' field='line1'>
                      <RadInput
                        placeholder='Enter line 1'
                        value={formValues.address?.line1}
                        onChange={(evt) => { setFormValues({ ...formValues, address: { ...formValues.address, line1: evt.detail.value } }) }}
                      />
                    </RadFormField>
                    <RadFormField label={isMobile ? null : '.'} field='line2'>
                      <RadInput
                        placeholder='Enter line 2'
                        value={formValues.address?.line2}
                        onChange={(evt) => { setFormValues({ ...formValues, address: { ...formValues.address, line2: evt.detail.value } }) }}
                      />
                    </RadFormField>
                  </RadGrid>
                  <RadGrid
                    gridDefinition={[
                      { colspan: { default: 12, xxs: 6 } },
                      { colspan: { default: 6, xxs: 3 } },
                      { colspan: { default: 6, xxs: 3 } }
                    ]}
                  >
                    <RadFormField field='city'>
                      <RadInput
                        placeholder='Enter city'
                        value={formValues.address?.city}
                        onChange={(evt) => { setFormValues({ ...formValues, address: { ...formValues.address, city: evt.detail.value } }) }}
                      />
                    </RadFormField>
                    <RadFormField field='state'>
                      <RadSelect
                        filteringType='auto'
                        placeholder='Choose a state'
                        options={stateListOptions}
                        selectedOption={formValues.address?.state != null ? stateListOptions.find(x => x.value === formValues.address.state) : null}
                        onChange={({ detail }) => { setFormValues({ ...formValues, address: { ...formValues.address, state: detail.selectedOption.value } }) }}
                      />
                    </RadFormField>
                    <RadFormField field='zip'>
                      <RadInput
                        placeholder='Enter ZIP code'
                        inputMode='numeric'
                        value={formValues.address?.zip}
                        onChange={(evt) => { setFormValues({ ...formValues, address: { ...formValues.address, zip: evt.detail.value } }) }}
                      />
                    </RadFormField>
                  </RadGrid>
                </RadContainer>

                <RadContainer
                  header={
                    <RadHeader variant='h2'>Sectors</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const sectors = formValues.sectors
                      sectors.push({ uuid: uuidv4() })
                      setFormValues({ ...formValues, sectors })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const sectors = [...formValues.sectors]
                      sectors.splice(itemIndex, 1)
                      setFormValues({ ...formValues, sectors })
                    }}
                    items={formValues.sectors}
                    addButtonText='Add new sector'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const filteredOptions = sectorOptions.filter((x) => !formValues.sectors.map((y) => y.sectorId?.toString()).includes(x.value))
                          return (
                            <RadFormField field={`sectors.${item.id ?? item.uuid}.id`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={sectorOptions.find(x => x.value === item.sectorId?.toString())}
                                onChange={({ detail }) => {
                                  const sectors = formValues.sectors
                                  const sector = formValues.sectors.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                  sector.sectorId = parseInt(detail.selectedOption.value)
                                  sector.name = detail.selectedOption.label
                                  sector.lastName = detail.selectedOption.label.split(' ')[1]
                                  setFormValues({ ...formValues, sectors })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a sector'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No sectors added to this organization.'
                  />
                </RadContainer>

                <RadContainer
                  header={
                    <RadHeader variant='h2'>People</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const people = formValues.people
                      people.push({ uuid: uuidv4(), primaryEmployer: false })
                      setFormValues({ ...formValues, people })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const people = [...formValues.people]
                      people.splice(itemIndex, 1)
                      setFormValues({ ...formValues, people })
                    }}
                    items={formValues.people}
                    addButtonText='Add new person'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const filteredOptions = personOptions.filter((x) => !formValues.people.map((y) => y.personId?.toString()).includes(x.value))
                          const person = formValues.people.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                          return (
                            <RadFormField field={`people.${item.id ?? item.uuid}.personId`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={personOptions.find(x => x.value === item.personId?.toString())}
                                onChange={({ detail }) => {
                                  const people = formValues.people
                                  person.personId = parseInt(detail.selectedOption.value)
                                  setFormValues({ ...formValues, people })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a person'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      },
                      {
                        label: 'Title',
                        control: item => {
                          const person = formValues.people.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                          return (
                            <RadFormField field={`people.${item.id ?? item.uuid}.title`}>
                              <RadInput
                                placeholder='Enter title'
                                ariaRequired
                                value={item.title}
                                onChange={({ detail }) => {
                                  const people = formValues.people
                                  person.title = detail.value
                                  setFormValues({ ...formValues, people })
                                }}
                              />
                            </RadFormField>
                          )
                        }
                      },
                      {
                        label: 'Primary Employer',
                        control: item => {
                          const person = formValues.people.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                          return (
                            <RadFormField field={`people.${item.id ?? item.uuid}.primaryEmployer`}>
                              <RadCheckbox
                                checked={person.primaryEmployer}
                                onChange={({ detail }) => {
                                  const people = formValues.people
                                  person.primaryEmployer = detail.checked
                                  setFormValues({ ...formValues, people })
                                }}
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No people added to this organization.'
                  />
                </RadContainer>

                {/* <RadContainer
                  header={
                    <RadHeader variant='h2'>Contacts</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const contacts = formValues.contacts
                      contacts.push({ uuid: uuidv4(), primaryEmployer: false })
                      setFormValues({ ...formValues, contacts })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const contacts = [...formValues.contacts]
                      contacts.splice(itemIndex, 1)
                      setFormValues({ ...formValues, contacts })
                    }}
                    items={formValues.contacts}
                    addButtonText='Add new contact'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const filteredOptions = personOptions.filter((x) => !formValues.contacts.map((y) => y.personId?.toString()).includes(x.value))
                          const person = formValues.contacts.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                          return (
                            <RadFormField field={`contacts.${item.id ?? item.uuid}.personId`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={personOptions.find(x => x.value === item.personId?.toString())}
                                onChange={({ detail }) => {
                                  const contacts = formValues.contacts
                                  person.personId = parseInt(detail.selectedOption.value)
                                  setFormValues({ ...formValues, contacts })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a contact'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No contacts added to this organization.'
                  />
                </RadContainer> */}

                {/* <RadContainer
                  header={
                    <RadHeader variant='h2'>Services</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const services = formValues.services
                      services.push({ uuid: uuidv4() })
                      setFormValues({ ...formValues, services })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const services = [...formValues.services]
                      services.splice(itemIndex, 1)
                      setFormValues({ ...formValues, services })
                    }}
                    items={formValues.services}
                    addButtonText='Add new service'
                    definition={[
                      {
                        label: 'Service *',
                        control: item => {
                          const filteredOptions = serviceOptions.filter((x) => !formValues.services.map((y) => y.serviceId?.toString()).includes(x.value))
                          return (
                            <RadFormField field={`services.${item.id ?? item.uuid}.id`}>
                              <RadSelect
                                filteringType='manual'
                                selectedOption={serviceOptions.find(x => x.value === item.serviceId?.toString())}
                                onChange={({ detail }) => {
                                  const services = formValues.services
                                  const service = formValues.services.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                  service.serviceId = parseInt(detail.selectedOption.value)
                                  service.name = detail.selectedOption.label
                                  setFormValues({ ...formValues, services })
                                }}
                                onLoadItems={({ detail }) => {
                                  setServiceFilteringText(detail.filteringText)
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a service'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No services added to this organization.'
                  />
                </RadContainer> */}

                {/* <RadContainer
                  header={
                    <RadHeader variant='h2'>Software</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const software = formValues.software
                      software.push({ uuid: uuidv4(), software: {} })
                      setFormValues({ ...formValues, software })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const software = [...formValues.software]
                      software.splice(itemIndex, 1)
                      setFormValues({ ...formValues, software })
                    }}
                    items={formValues.software}
                    addButtonText='Add new software'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const softwareOptions = software.map((x) => { return { value: x.id.toString(), label: x.name } })
                          const filteredOptions = (softwareOptions ?? []).filter((x) => !formValues.software.map((y) => y.name).includes(x.value))
                          return (
                            <RadFormField field={`software.${item.id ?? item.uuid}.name`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={softwareOptions.find(x => x.value === item.softwareId?.toString())}
                                onChange={({ detail }) => {
                                  const software = formValues.software
                                  const thisSoftware = formValues.software.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                  thisSoftware.id = null
                                  thisSoftware.softwareId = parseInt(detail.selectedOption.value)
                                  thisSoftware.software.name = detail.selectedOption.label
                                  setFormValues({ ...formValues, software })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a software'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      }

                    ]}
                    removeButtonText='Remove'
                    empty='No software added to this organization.'
                  />
                </RadContainer> */}

                {/* <RadContainer
                  header={
                    <RadHeader
                      variant='h2'
                      description='Target areas are states, counties, cities or zip codes.'
                    >
                      Target Areas
                    </RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const targetAreas = formValues.targetAreas
                      targetAreas.push({ uuid: uuidv4(), targetArea: {} })
                      setFormValues({ ...formValues, targetAreas })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const targetAreas = [...formValues.targetAreas]
                      targetAreas.splice(itemIndex, 1)
                      setFormValues({ ...formValues, targetAreas })
                    }}
                    items={formValues.targetAreas}
                    addButtonText='Add new target area'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const targetAreas = formValues.targetAreas
                          const targetArea = formValues.targetAreas.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                          const filteredOptions = (targetAreaOptions ?? []).filter((x) => formValues.targetAreas.find((y) => y.targetArea.name === x.label) == null)
                          return (
                            <RadFormField field={`targetAreas.${item.id ?? item.uuid}.name`}>
                              <RadSelect
                                selectedOption={item.targetAreaId != null ? { value: item.targetAreaId, label: item.targetArea.name } : null}
                                onChange={({ detail }) => {
                                  targetArea.targetAreaId = parseInt(detail.selectedOption.value)
                                  targetArea.targetArea.name = detail.selectedOption.label
                                  setFormValues({ ...formValues, targetAreas })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a target area'
                                filteringType='manual'
                                onLoadItems={({ detail }) => {
                                  setTargetAreaFilteringText(detail.filteringText)
                                }}
                                empty={targetAreaFilteringText !== '' ? 'No matches found' : null}
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No target areas added to this organization.'
                  />
                </RadContainer> */}

                {/* Target Populations */}
                {/* <RadContainer
                  header={
                    <RadHeader variant='h2'>Target Populations</RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadAttributeEditor
                      onAddButtonClick={() => {
                        const targetPopulations = formValues.targetPopulations
                        targetPopulations.push({ uuid: uuidv4(), targetPopulation: {} })
                        setFormValues({ ...formValues, targetPopulations })
                      }}
                      onRemoveButtonClick={({
                        detail: { itemIndex }
                      }) => {
                        const targetPopulations = [...formValues.targetPopulations]
                        targetPopulations.splice(itemIndex, 1)
                        setFormValues({ ...formValues, targetPopulations })
                      }}
                      items={formValues.targetPopulations}
                      addButtonText='Add new target population'
                      definition={[
                        {
                          label: 'Name',
                          control: item => {
                            const targetPopulationOptions = targetPopulations.map((x) => { return { value: x.id.toString(), label: x.name } })
                            const filteredOptions = (targetPopulationOptions ?? []).filter((x) => !formValues.targetPopulations.map((y) => y.name).includes(x.value))
                            return (
                              <RadFormField field={`targetPopulations.${item.id || item.uuid}.name`}>
                                <RadAutosuggest
                                  options={filteredOptions}
                                  value={item.targetPopulation.name ?? ''}
                                  enteredTextLabel={value => `Use: "${value}"`}
                                  placeholder='Enter target population'
                                  onChange={({ detail }) => {
                                    const targetPopulations = formValues.targetPopulations
                                    const targetPopulation = formValues.targetPopulations.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                    const selectedOption = targetPopulationOptions.find((x) => x.value === detail.value)
                                    targetPopulation.id = null
                                    if (selectedOption == null) {
                                      targetPopulation.targetPopulationId = null
                                      targetPopulation.targetPopulation.name = detail.value
                                    } else {
                                      targetPopulation.targetPopulationId = parseInt(selectedOption.value)
                                      targetPopulation.targetPopulation.name = selectedOption.label
                                    }
                                    setFormValues({ ...formValues, targetPopulations })
                                  }}
                                />
                              </RadFormField>
                            )
                          }
                        }
                      ]}
                      removeButtonText='Remove'
                      empty='No target populations added to this organization.'
                    />
                  </RadSpaceBetween>
                </RadContainer> */}

                {/* Tags */}
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Tags</RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadAttributeEditor
                      onAddButtonClick={() => {
                        const tags = formValues.tags
                        tags.push({ uuid: uuidv4(), tag: {} })
                        setFormValues({ ...formValues, tags })
                      }}
                      onRemoveButtonClick={({
                        detail: { itemIndex }
                      }) => {
                        const tags = [...formValues.tags]
                        tags.splice(itemIndex, 1)
                        setFormValues({ ...formValues, tags })
                      }}
                      items={formValues.tags}
                      addButtonText='Add new tag'
                      definition={[
                        {
                          label: 'Name *',
                          control: item => {
                            const tagOptions = tags.map((x) => { return { id: x.id, value: x.name } })
                            const filteredOptions = (tagOptions ?? []).filter((x) => !formValues.tags.map((y) => y.tag.name).includes(x.value))
                            return (
                              <RadFormField field={`tag.${item.id || item.uuid}.name`}>
                                <RadAutosuggest
                                  options={filteredOptions}
                                  value={item.tag.name ?? ''}
                                  enteredTextLabel={value => `Use: "${value}"`}
                                  placeholder='Enter tag'
                                  empty='No matches found'
                                  onChange={({ detail }) => {
                                    const tags = formValues.tags
                                    const id = tagOptions.find((x) => x.value === detail.value)?.id
                                    item.id = null
                                    item.tag.id = id
                                    item.tag.name = detail.value
                                    setFormValues({ ...formValues, tags })
                                  }}
                                />
                              </RadFormField>
                            )
                          }
                        }
                      ]}
                      removeButtonText='Remove'
                      empty='No tags added to this organization.'
                    />
                  </RadSpaceBetween>
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
