import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadAutosuggest } from '../common/RadAutosuggest'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'

export function DataProductEdit () {
  const navigate = useNavigate()
  const { dataProductId } = useParams()
  const [formValues, setFormValues] = useState()
  const { data: dataProduct } = useGet(dataProductId ? `/api/data-product/${dataProductId}` : null)
  const { data: dataAssetOptions } = useGet('/api/option/data-asset?required=true')
  const { data: insightOptions } = useGet('/api/option/insight?required=true')
  const { data: personOptions } = useGet('/api/option/person')
  const { data: tags } = useGet('/api/tag')
  const create = usePost('/api/data-product', formValues, (resp) => { navigate(`/data-product/${resp.id}`) })
  const update = usePut(`/api/data-product/${dataProductId}`, formValues, (resp) => { navigate(`/data-product/${dataProductId}`) })

  useEffect(() => {
    const defaultFormValues = { dataAssets: [], governanceIndicators: [], tags: [] }
    setFormValues(dataProduct ?? defaultFormValues)
  }, [dataProduct])

  async function cancel () {
    if (dataProductId != null) {
      navigate(`/data-product/${dataProductId}`)
    } else {
      navigate('/data-product')
    }
  }

  async function saveChanges () {
    if (dataProductId != null) {
      update()
    } else {
      create()
    }
  }

  if (
    formValues != null &&
    dataAssetOptions != null &&
    insightOptions != null &&
    personOptions != null &&
    tags != null
  ) {
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>{dataProductId ? 'Edit' : 'New'} Data Product</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Name *' field='name'>
                      <RadInput
                        ariaRequired
                        placeholder='Enter name'
                        value={formValues.name}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Description *' field='description'>
                      <RadTextarea
                        placeholder='Enter description'
                        value={formValues.description}
                        onChange={({ detail }) => setFormValues({ ...formValues, description: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Administrator *' field='administratorId' constraintText=''>
                      <RadSelect
                        filteringType='auto'
                        selectedOption={personOptions.find(x => x.value === formValues.administratorId?.toString())}
                        onChange={({ detail }) => {
                          setFormValues({ ...formValues, administratorId: parseInt(detail.selectedOption.value) })
                        }}
                        options={personOptions}
                        enteredTextLabel={value => value}
                        selectedAriaLabel='Selected'
                        placeholder='Choose a administrator'
                        empty='No matches found'
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Data Assets *</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const dataAssets = formValues.dataAssets
                      dataAssets.push({ uuid: uuidv4(), primaryEmployer: false })
                      setFormValues({ ...formValues, dataAssets })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const dataAssets = [...formValues.dataAssets]
                      dataAssets.splice(itemIndex, 1)
                      setFormValues({ ...formValues, dataAssets })
                    }}
                    items={formValues.dataAssets}
                    addButtonText='Add new data asset'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const filteredOptions = dataAssetOptions.filter((x) => !formValues.dataAssets.map((y) => y.dataAssetId?.toString()).includes(x.value))
                          return (
                            <RadFormField field={`dataAsset.${item.id ?? item.uuid}.dataAssetId`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={dataAssetOptions.find(x => x.value === item.dataAssetId?.toString())}
                                onChange={({ detail }) => {
                                  const dataAssets = formValues.dataAssets
                                  item.dataAssetId = parseInt(detail.selectedOption.value)
                                  setFormValues({ ...formValues, dataAssets })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a data asset'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No data assets added to this data product.'
                  />
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Tags</RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadAttributeEditor
                      onAddButtonClick={() => {
                        const tags = formValues.tags
                        tags.push({ uuid: uuidv4(), tag: {} })
                        setFormValues({ ...formValues, tags })
                      }}
                      onRemoveButtonClick={({
                        detail: { itemIndex }
                      }) => {
                        const tags = [...formValues.tags]
                        tags.splice(itemIndex, 1)
                        setFormValues({ ...formValues, tags })
                      }}
                      items={formValues.tags}
                      addButtonText='Add new tag'
                      definition={[
                        {
                          label: 'Name *',
                          control: item => {
                            const tagOptions = tags.map((x) => { return { id: x.id, value: x.name } })
                            const filteredOptions = (tagOptions ?? []).filter((x) => !formValues.tags.map((y) => y.tag.name).includes(x.value))
                            return (
                              <RadFormField field={`tag.${item.id || item.uuid}.name`}>
                                <RadAutosuggest
                                  options={filteredOptions}
                                  value={item.tag.name ?? ''}
                                  enteredTextLabel={value => `Use: "${value}"`}
                                  placeholder='Enter tag'
                                  empty='No matches found'
                                  onChange={({ detail }) => {
                                    const tags = formValues.tags
                                    const id = tagOptions.find((x) => x.value === detail.value)?.id
                                    item.id = null
                                    item.tag.id = id
                                    item.tag.name = detail.value
                                    setFormValues({ ...formValues, tags })
                                  }}
                                />
                              </RadFormField>
                            )
                          }
                        }
                      ]}
                      removeButtonText='Remove'
                      empty='No tags added to this data product.'
                    />
                  </RadSpaceBetween>
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
