
    import './styles.scoped.css';
    export default {
  "arrow": "awsui_arrow_xjuzf_am5g4_285",
  "arrow-outer": "awsui_arrow-outer_xjuzf_am5g4_289",
  "arrow-inner": "awsui_arrow-inner_xjuzf_am5g4_289",
  "refresh": "awsui_refresh_xjuzf_am5g4_319",
  "arrow-position-right-top": "awsui_arrow-position-right-top_xjuzf_am5g4_329",
  "arrow-position-right-bottom": "awsui_arrow-position-right-bottom_xjuzf_am5g4_329",
  "arrow-position-left-top": "awsui_arrow-position-left-top_xjuzf_am5g4_332",
  "arrow-position-left-bottom": "awsui_arrow-position-left-bottom_xjuzf_am5g4_332",
  "arrow-position-top-center": "awsui_arrow-position-top-center_xjuzf_am5g4_335",
  "arrow-position-top-right": "awsui_arrow-position-top-right_xjuzf_am5g4_335",
  "arrow-position-top-left": "awsui_arrow-position-top-left_xjuzf_am5g4_335",
  "arrow-position-top-responsive": "awsui_arrow-position-top-responsive_xjuzf_am5g4_335",
  "arrow-position-bottom-center": "awsui_arrow-position-bottom-center_xjuzf_am5g4_338",
  "arrow-position-bottom-right": "awsui_arrow-position-bottom-right_xjuzf_am5g4_338",
  "arrow-position-bottom-left": "awsui_arrow-position-bottom-left_xjuzf_am5g4_338",
  "arrow-position-bottom-responsive": "awsui_arrow-position-bottom-responsive_xjuzf_am5g4_338",
  "body": "awsui_body_xjuzf_am5g4_484",
  "body-overflow-visible": "awsui_body-overflow-visible_xjuzf_am5g4_523",
  "has-dismiss": "awsui_has-dismiss_xjuzf_am5g4_527",
  "dismiss": "awsui_dismiss_xjuzf_am5g4_532",
  "dismiss-control": "awsui_dismiss-control_xjuzf_am5g4_540",
  "header-row": "awsui_header-row_xjuzf_am5g4_544",
  "header": "awsui_header_xjuzf_am5g4_544",
  "content": "awsui_content_xjuzf_am5g4_570",
  "content-overflow-visible": "awsui_content-overflow-visible_xjuzf_am5g4_578",
  "container": "awsui_container_xjuzf_am5g4_724",
  "container-body": "awsui_container-body_xjuzf_am5g4_732",
  "container-body-variant-annotation": "awsui_container-body-variant-annotation_xjuzf_am5g4_775",
  "container-body-size-small": "awsui_container-body-size-small_xjuzf_am5g4_780",
  "fixed-width": "awsui_fixed-width_xjuzf_am5g4_783",
  "container-body-size-medium": "awsui_container-body-size-medium_xjuzf_am5g4_787",
  "container-body-size-large": "awsui_container-body-size-large_xjuzf_am5g4_794",
  "container-arrow": "awsui_container-arrow_xjuzf_am5g4_806",
  "container-arrow-position-right-top": "awsui_container-arrow-position-right-top_xjuzf_am5g4_810",
  "container-arrow-position-right-bottom": "awsui_container-arrow-position-right-bottom_xjuzf_am5g4_810",
  "container-arrow-position-left-top": "awsui_container-arrow-position-left-top_xjuzf_am5g4_822",
  "container-arrow-position-left-bottom": "awsui_container-arrow-position-left-bottom_xjuzf_am5g4_822",
  "container-arrow-position-top-center": "awsui_container-arrow-position-top-center_xjuzf_am5g4_834",
  "container-arrow-position-top-right": "awsui_container-arrow-position-top-right_xjuzf_am5g4_834",
  "container-arrow-position-top-left": "awsui_container-arrow-position-top-left_xjuzf_am5g4_834",
  "container-arrow-position-top-responsive": "awsui_container-arrow-position-top-responsive_xjuzf_am5g4_834",
  "container-arrow-position-bottom-center": "awsui_container-arrow-position-bottom-center_xjuzf_am5g4_850",
  "container-arrow-position-bottom-right": "awsui_container-arrow-position-bottom-right_xjuzf_am5g4_854",
  "container-arrow-position-bottom-left": "awsui_container-arrow-position-bottom-left_xjuzf_am5g4_858",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_xjuzf_am5g4_1",
  "root": "awsui_root_xjuzf_am5g4_1048",
  "trigger": "awsui_trigger_xjuzf_am5g4_1084",
  "trigger-type-text": "awsui_trigger-type-text_xjuzf_am5g4_1091",
  "trigger-inner-text": "awsui_trigger-inner-text_xjuzf_am5g4_1126",
  "popover-inline-content": "awsui_popover-inline-content_xjuzf_am5g4_1130"
};
  