import React from 'react'
import './SignIn.scss'

export function SignIn () {
  return (
    <div className='sign-in'>
      <div className='content' />
    </div>
  )
}
