import React, { useState } from 'react'
import { useGet } from '../hooks/useGet'
import { Avatar } from '@rmwc/avatar'
import { RadBox } from '../common/RadBox'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadLineBreakRenderer } from '../common/RadLineBreakRenderer'
import './MIO.scss'

export function Mio ({ messageIn, messageOut, suggestions, onEnter, thumbsUp, thumbsDown }) {
  const [width, setWidth] = useState(50)
  const { data: userInfo } = useGet('/api/user/current')

  if (
    userInfo != null
  ) {
    return (
      <div className='mio'>
        <div className='chat'>
          <div className='actions'>
            <button disabled={width >= 80} onClick={() => setWidth(width + 10)}><span className='material-symbols-outlined'>add</span></button>
            <button disabled={width <= 30} onClick={() => setWidth(width - 10)}><span className='material-symbols-outlined'>remove</span></button>
          </div>
          <RadSpaceBetween size='l'>
            {messageOut != null &&
              <div className='message-out'>
                <RadLineBreakRenderer className='message' text={messageOut} />
                <Avatar
                  size='large'
                  name={userInfo.name}
                  referrerPolicy='no-referrer'
                />
              </div>}
            {messageIn != null &&
              <div className='message-in'>
                <Avatar
                  src='../mio.png'
                  size='large'
                  name='MIO'
                  referrerPolicy='no-referrer'
                />
                <RadLineBreakRenderer className='message' text={messageIn.output} />
              </div>}
            {messageIn != null && messageIn.output !== 'I seem to be having trouble. Please try again later.' && messageIn.output !== 'Hi, I\'m MIO. How can I help you?' &&
              <div className='thumbs'>
                <button
                  className='material-symbols-outlined'
                  onClick={thumbsUp}
                  disabled={messageIn.cached}
                >
                  thumb_up
                </button>
                <button
                  className='material-symbols-outlined'
                  onClick={thumbsDown}
                  disabled={!messageIn.cached}
                >
                  thumb_down
                </button>
              </div>}
            {messageIn == null &&
              <div className='message-in'>
                <Avatar
                  src='../mio.png'
                  size='large'
                  name='MIO'
                  referrerPolicy='no-referrer'
                />
                <div className='typing'>
                  <div className='typing__dot' />
                  <div className='typing__dot' />
                  <div className='typing__dot' />
                </div>
              </div>}
            <Input onEnter={onEnter} />
            {suggestions.length > 0 &&
              <RadBox padding={{ top: 'xxs' }}>
                <div className='suggestions'>
                  <span>Suggestions:</span>
                  <div>
                    {suggestions.map((x, index) =>
                      <button
                        key={index}
                        onClick={() => onEnter({ text: x })}
                      >
                        {x}
                      </button>
                    )}
                  </div>
                </div>
              </RadBox>}
          </RadSpaceBetween>
        </div>
      </div>
    )
  }
}

function Input ({ initialValue, onEnter }) {
  const [value, setValue] = useState(initialValue ?? '')

  return (
    <div className='input'>
      <textarea
        inputMode='text'
        type='text'
        placeholder='Enter message'
        value={value}
        onChange={(event) => {
          const value = event.target.value
          setValue(value)
          if (value.trim() === '') { setValue('') }
        }}
        onKeyDown={(x) => {
          if (x.key === 'Enter' && !x.shiftKey) {
            onEnter({ text: value })
            setValue('')
          }
        }}
      />
      <button
        className='material-symbols-outlined'
        onClick={() => {
          if (value.trim() !== '') {
            onEnter({ text: value })
            setValue('')
          }
        }}
      >
        send
      </button>
    </div>
  )
}
