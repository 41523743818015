import React, { useContext } from 'react'
import { AppContext } from './../App'
import { useLocation } from 'react-router-dom'
import { useIsMobile } from '../hooks/useIsMobile'
import SideNavigation from '@cloudscape-design/components/side-navigation'

// https://cloudscape.design/components/side-navigation
export function RadSideNavigation ({ setNavigationOpen }) {
  const { setError } = useContext(AppContext)
  const location = useLocation()
  const isMobile = useIsMobile()
  const rootHref = () => {
    let path = location.pathname
    let last = path.split('/').pop()
    while (!isNaN(parseInt(last, 10)) || last === 'edit') {
      path = path.replace(`/${last}`, '')
      last = path.split('/').pop()
    }
    return path
  }
  const [activeHref, setActiveHref] = React.useState(rootHref)

  return (
    <SideNavigation
      activeHref={activeHref}
      // header={{ href: '/', logo: { alt: 'logo', src: '/mio.png' } }}
      onFollow={(event) => {
        if (isMobile) setNavigationOpen(false)
        setError(null) // Chipi - I might not need this
        setActiveHref(event.detail.href)
      }}
      items={[
        {
          type: 'section-group',
          title: 'Menu',
          items: [
            { type: 'link', text: 'Dashboard', href: '/' },
            {
              type: 'section',
              text: 'Data',
              items: [
                { type: 'link', text: 'Sources', href: '/data-source' },
                { type: 'link', text: 'Assets', href: '/data-asset' },
                { type: 'link', text: 'Products', href: '/data-product' }
              ]
            },
            { type: 'link', text: 'Collaboratives', href: '/collaborative' },
            { type: 'link', text: 'Insights', href: '/insight' },
            { type: 'link', text: 'Organizations', href: '/organization' },
            { type: 'link', text: 'People', href: '/person' },
            { type: 'link', text: 'Projects', href: '/project' },
            { type: 'divider' },
            { type: 'link', text: 'Tags', href: '/tag' },
            { type: 'link', text: 'Types', href: '/type' },
            { type: 'link', text: 'Roles', href: '/role' },
            { type: 'link', text: 'Sectors', href: '/sector' },
            { type: 'link', text: 'Users', href: '/user' },
            { type: 'link', text: 'Vendors', href: '/vendor' },
            { type: 'divider' },
            { type: 'link', text: 'Agents', href: '/agent' }
          ]
        }
      ]}
    />
  )
}
