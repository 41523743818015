
    import './styles.scoped.css';
    export default {
  "item-element": "awsui_item-element_93a1u_9oj7k_143",
  "disabled": "awsui_disabled_93a1u_9oj7k_155",
  "show-divider": "awsui_show-divider_93a1u_9oj7k_162",
  "highlighted": "awsui_highlighted_93a1u_9oj7k_165",
  "is-focused": "awsui_is-focused_93a1u_9oj7k_180",
  "menu-item": "awsui_menu-item_93a1u_9oj7k_185",
  "has-category-header": "awsui_has-category-header_93a1u_9oj7k_199",
  "icon": "awsui_icon_93a1u_9oj7k_203",
  "external-icon": "awsui_external-icon_93a1u_9oj7k_208"
};
  