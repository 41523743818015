import React, { createContext, useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { firebase, auth, ui } from './firebase'
import { SignIn } from './SignIn'
import { RadTopNavigation } from './common/RadTopNavigation'
import { IsLoading } from './common/IsLoading'
import { RadNotFound } from './common/RadNotFound'
import { RadPermissionDenied } from './common/RadPermissionDenied'
import { RadConfirmationModal } from './common/RadConfirmationModal'
import { Dashboard } from './Dashboard'
import { AgentDetail } from './agent/AgentDetail'
import { AgentEdit } from './agent/AgentEdit'
import { AgentList } from './agent/AgentList'
import { CollaborativeDetail } from './collaborative/CollaborativeDetail'
import { CollaborativeEdit } from './collaborative/CollaborativeEdit'
import { CollaborativeList } from './collaborative/CollaborativeList'
import { DataAssetDetail } from './dataAsset/DataAssetDetail'
import { DataAssetEdit } from './dataAsset/DataAssetEdit'
import { DataAssetList } from './dataAsset/DataAssetList'
import { DataProductDetail } from './dataProduct/DataProductDetail'
import { DataProductEdit } from './dataProduct/DataProductEdit'
import { DataProductList } from './dataProduct/DataProductList'
import { DataSourceDetail } from './dataSource/DataSourceDetail'
import { DataSourceEdit } from './dataSource/DataSourceEdit'
import { DataSourceList } from './dataSource/DataSourceList'
import { InsightDetail } from './insight/InsightDetail'
import { InsightEdit } from './insight/InsightEdit'
import { InsightList } from './insight/InsightList'
import { OrganizationDetail } from './organization/OrganizationDetail'
import { OrganizationEdit } from './organization/OrganizationEdit'
import { OrganizationList } from './organization/OrganizationList'
import { PersonDetail } from './person/PersonDetail'
import { PersonEdit } from './person/PersonEdit'
import { PersonList } from './person/PersonList'
import { ProjectDetail } from './project/ProjectDetail'
import { ProjectEdit } from './project/ProjectEdit'
import { ProjectList } from './project/ProjectList'
import { RoleDetail } from './role/RoleDetail'
import { RoleEdit } from './role/RoleEdit'
import { RoleList } from './role/RoleList'
import { SectorDetail } from './sector/SectorDetail'
import { SectorEdit } from './sector/SectorEdit'
import { SectorList } from './sector/SectorList'
import { TagDetail } from './tag/TagDetail'
import { TagEdit } from './tag/TagEdit'
import { TagList } from './tag/TagList'
import { TypeEdit } from './type/TypeEdit'
import { TypeList } from './type/TypeList'
import { UserDetail } from './user/UserDetail'
import { UserEdit } from './user/UserEdit'
import { UserList } from './user/UserList'
import { VendorDetail } from './vendor/VendorDetail'
import { VendorEdit } from './vendor/VendorEdit'
import { VendorList } from './vendor/VendorList'

import '@cloudscape-design/global-styles/index.css'
import './App.scss'

export const AppContext = createContext(null)

export function App () {
  const [user, setUser] = useState(undefined)
  const [loadingCount, setLoadingCount] = useState(1)
  const [notFound, setNotFound] = useState(false)
  const [permissionDenied, setPermissionDenied] = useState(null)
  const [error, setError] = useState()
  const [navigationOpen, setNavigationOpen] = useState(window.localStorage.getItem('navigationOpen') === 'true')
  const [reloadCounter, setReloadCounter] = useState(0)
  const [confirmModal, setConfirmModal] = useState({ visible: false })

  useEffect(() => {
    window.localStorage.setItem('navigationOpen', navigationOpen)
  }, [navigationOpen])

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setUser(user)
      setLoadingCount(count => count - 1)
    })
    return () => unregisterAuthObserver()
  }, [])

  if (user === undefined) {
    return <IsLoading loadingCount={loadingCount} />
  }

  // link to docs for options
  // https://firebase.google.com/docs/auth/web/firebaseui
  if (user === null) {
    ui.start('#firebaseui-auth-container', {
      signInFlow: 'popup',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        signInFailure: function (error) {
          console.log(error)
        },
        signInSuccessWithAuthResult: function (result) {
          ui.reset()
          return false
        }
      }
    })
    ui.disableAutoSignIn()
    return <SignIn />
  }

  return (
    <BrowserRouter>
      <AppContext.Provider value={{
        user,
        loadingCount,
        setLoadingCount,
        notFound,
        setNotFound,
        permissionDenied,
        setPermissionDenied,
        reloadCounter,
        setReloadCounter,
        error,
        setError,
        navigationOpen,
        setNavigationOpen,
        confirmModal,
        setConfirmModal
      }}
      >
        <RadTopNavigation />
        <Routes>
          <Route path='/' element={<Dashboard />} />

          <Route path='/agent' element={<AgentList />} />
          <Route path='/agent/:agentId' element={<AgentDetail />} />
          <Route path='/agent/create' element={<AgentEdit />} />
          <Route path='/agent/:agentId/edit' element={<AgentEdit />} />

          <Route path='/collaborative' element={<CollaborativeList />} />
          <Route path='/collaborative/:collaborativeId' element={<CollaborativeDetail />} />
          <Route path='/collaborative/create' element={<CollaborativeEdit />} />
          <Route path='/collaborative/:collaborativeId/edit' element={<CollaborativeEdit />} />

          <Route path='/data-asset' element={<DataAssetList />} />
          <Route path='/data-asset/:dataAssetId' element={<DataAssetDetail />} />
          <Route path='/data-asset/create' element={<DataAssetEdit />} />
          <Route path='/data-asset/:dataAssetId/edit' element={<DataAssetEdit />} />

          <Route path='/data-product' element={<DataProductList />} />
          <Route path='/data-product/:dataProductId' element={<DataProductDetail />} />
          <Route path='/data-product/create' element={<DataProductEdit />} />
          <Route path='/data-product/:dataProductId/edit' element={<DataProductEdit />} />

          <Route path='/data-source' element={<DataSourceList />} />
          <Route path='/data-source/:dataSourceId' element={<DataSourceDetail />} />
          <Route path='/data-source/create' element={<DataSourceEdit />} />
          <Route path='/data-source/:dataSourceId/edit' element={<DataSourceEdit />} />

          <Route path='/insight' element={<InsightList />} />
          <Route path='/insight/:insightId' element={<InsightDetail />} />
          <Route path='/insight/create' element={<InsightEdit />} />
          <Route path='/insight/:insightId/edit' element={<InsightEdit />} />

          <Route path='/organization' element={<OrganizationList />} />
          <Route path='/organization/:organizationId' element={<OrganizationDetail />} />
          <Route path='/organization/create' element={<OrganizationEdit />} />
          <Route path='/organization/:organizationId/edit' element={<OrganizationEdit />} />

          <Route path='/person' element={<PersonList />} />
          <Route path='/person/:personId' element={<PersonDetail />} />
          <Route path='/person/create' element={<PersonEdit />} />
          <Route path='/person/:personId/edit' element={<PersonEdit />} />

          <Route path='/project' element={<ProjectList />} />
          <Route path='/project/:projectId' element={<ProjectDetail />} />
          <Route path='/project/create' element={<ProjectEdit />} />
          <Route path='/project/:projectId/edit' element={<ProjectEdit />} />

          <Route path='/role' element={<RoleList />} />
          <Route path='/role/create' element={<RoleEdit />} />
          <Route path='/role/:roleId' element={<RoleDetail />} />
          <Route path='/role/:roleId/edit' element={<RoleEdit />} />

          <Route path='/sector' element={<SectorList />} />
          <Route path='/sector/create' element={<SectorEdit />} />
          <Route path='/sector/:sectorId' element={<SectorDetail />} />
          <Route path='/sector/:sectorId/edit' element={<SectorEdit />} />

          <Route path='/tag' element={<TagList />} />
          <Route path='/tag/create' element={<TagEdit />} />
          <Route path='/tag/:tagId' element={<TagDetail />} />
          <Route path='/tag/:tagId/edit' element={<TagEdit />} />

          <Route path='/type' element={<TypeList />} />
          <Route path='/type/:entity' element={<TypeEdit />} />

          <Route path='/user' element={<UserList />} />
          <Route path='/user/create' element={<UserEdit />} />
          <Route path='/user/:userId' element={<UserDetail />} />
          <Route path='/user/:userId/edit' element={<UserEdit />} />

          <Route path='/vendor' element={<VendorList />} />
          <Route path='/vendor/:vendorId' element={<VendorDetail />} />
          <Route path='/vendor/create' element={<VendorEdit />} />
          <Route path='/vendor/:vendorId/edit' element={<VendorEdit />} />

          <Route path='*' element={<RadNotFound />} />
        </Routes>
        <IsLoading loadingCount={loadingCount} />
        {loadingCount === 0 && notFound && <RadNotFound />}
        {loadingCount === 0 && permissionDenied != null && <RadPermissionDenied message={permissionDenied} />}
        <RadConfirmationModal />
      </AppContext.Provider>
    </BrowserRouter>
  )
}
