import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function VendorDetail () {
  const navigate = useNavigate()
  const { vendorId } = useParams()
  const { data: vendor } = useGet(`/api/vendor/${vendorId}`, true)
  const confirmDelete = useConfirm('Delete data product?', 'Delete data product permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/vendor/${vendorId}`, () => { navigate('/vendor') })

  if (vendor != null) {
    return (
      <RadAppLayout
        name={vendor.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={vendor.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
            description={vendor.description}
          >
            {vendor.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Products
                </RadHeader>
              }
            >
              <RadSpaceBetween size='xxs'>
                {vendor.products.map((x) => <div key={x.id}>{x.name}</div>)}
              </RadSpaceBetween>
            </RadContainer>
          </RadSpaceBetween>
        }
      />
    )
  }
}
