import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function ProjectDetail () {
  const navigate = useNavigate()
  const { projectId } = useParams()
  const { data: project } = useGet(`/api/project/${projectId}`, true)
  const confirmDelete = useConfirm('Delete data asset?', 'Delete data asset permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/project/${projectId}`, () => { navigate('/project') })

  if (project != null) {
    return (
      <RadAppLayout
        name={project.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={project.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
            description={project.description}
          >
            {project.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadColumnLayout columns={4} borders='vertical'>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Status
                  </RadBox>
                  <div>{project.status.name}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Phase
                  </RadBox>
                  <div>{project.phase.name}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Sponsor
                  </RadBox>
                  <RadLink href={`/person/${project.sponsorId}`}>{project.sponsor.fullName}</RadLink>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Data Products
                  </RadBox>
                  <div>{project.dataProducts.length > 0
                    ? project.dataProducts.map((x) => <div key={x.id}><RadLink href={`/data-product/${x.dataProduct.id}`}>{x.dataProduct.name}</RadLink></div>)
                    : '-'}
                  </div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Partners
                  </RadBox>
                  <div>{project.partners.length > 0
                    ? project.partners.map((x) =>
                      <div key={x.id}><RadLink href={`/${x.collaborative != null ? 'collaborative' : ''}${x.organization != null ? 'organization' : ''}/${x.collaborativeId || x.organizationId}`}>{x.collaborative?.name || x.organization?.name}</RadLink></div>)
                    : '-'}
                  </div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Insights
                  </RadBox>
                  <div>{project.insights.length > 0
                    ? project.insights.map((x) => <div key={x.id}><RadLink href={`/insight/${x.insight.id}`}>{x.insight.name}</RadLink></div>)
                    : '-'}
                  </div>
                </div>
              </RadColumnLayout>
            </RadContainer>
          </RadSpaceBetween>
        }
      />
    )
  }
}
