
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_18wu0_1pq8u_143",
  "box": "awsui_box_18wu0_1pq8u_310",
  "p-variant": "awsui_p-variant_18wu0_1pq8u_310",
  "color-default": "awsui_color-default_18wu0_1pq8u_310",
  "b-variant": "awsui_b-variant_18wu0_1pq8u_310",
  "strong-variant": "awsui_strong-variant_18wu0_1pq8u_310",
  "code-variant": "awsui_code-variant_18wu0_1pq8u_310",
  "pre-variant": "awsui_pre-variant_18wu0_1pq8u_310",
  "samp-variant": "awsui_samp-variant_18wu0_1pq8u_310",
  "h1-variant": "awsui_h1-variant_18wu0_1pq8u_314",
  "h2-variant": "awsui_h2-variant_18wu0_1pq8u_314",
  "h3-variant": "awsui_h3-variant_18wu0_1pq8u_314",
  "h4-variant": "awsui_h4-variant_18wu0_1pq8u_314",
  "h5-variant": "awsui_h5-variant_18wu0_1pq8u_314",
  "small-variant": "awsui_small-variant_18wu0_1pq8u_318",
  "a-variant": "awsui_a-variant_18wu0_1pq8u_322",
  "font-size-default": "awsui_font-size-default_18wu0_1pq8u_326",
  "font-weight-default": "awsui_font-weight-default_18wu0_1pq8u_366",
  "key-label-variant": "awsui_key-label-variant_18wu0_1pq8u_408",
  "gen-ai-label-variant": "awsui_gen-ai-label-variant_18wu0_1pq8u_415",
  "value-large-variant": "awsui_value-large-variant_18wu0_1pq8u_422",
  "font-weight-heavy": "awsui_font-weight-heavy_18wu0_1pq8u_429",
  "color-inverted": "awsui_color-inverted_18wu0_1pq8u_434",
  "color-text-label": "awsui_color-text-label_18wu0_1pq8u_437",
  "color-text-body-secondary": "awsui_color-text-body-secondary_18wu0_1pq8u_440",
  "color-text-status-error": "awsui_color-text-status-error_18wu0_1pq8u_443",
  "color-text-status-success": "awsui_color-text-status-success_18wu0_1pq8u_446",
  "color-text-status-info": "awsui_color-text-status-info_18wu0_1pq8u_449",
  "color-text-status-inactive": "awsui_color-text-status-inactive_18wu0_1pq8u_452",
  "color-text-status-warning": "awsui_color-text-status-warning_18wu0_1pq8u_455",
  "color-inherit": "awsui_color-inherit_18wu0_1pq8u_458",
  "font-size-body-s": "awsui_font-size-body-s_18wu0_1pq8u_461",
  "font-size-body-m": "awsui_font-size-body-m_18wu0_1pq8u_466",
  "font-size-heading-xs": "awsui_font-size-heading-xs_18wu0_1pq8u_470",
  "font-size-heading-s": "awsui_font-size-heading-s_18wu0_1pq8u_474",
  "font-size-heading-m": "awsui_font-size-heading-m_18wu0_1pq8u_479",
  "font-size-heading-l": "awsui_font-size-heading-l_18wu0_1pq8u_484",
  "font-size-heading-xl": "awsui_font-size-heading-xl_18wu0_1pq8u_489",
  "font-size-display-l": "awsui_font-size-display-l_18wu0_1pq8u_494",
  "font-weight-light": "awsui_font-weight-light_18wu0_1pq8u_499",
  "font-weight-normal": "awsui_font-weight-normal_18wu0_1pq8u_502",
  "font-weight-bold": "awsui_font-weight-bold_18wu0_1pq8u_505",
  "t-left": "awsui_t-left_18wu0_1pq8u_512",
  "t-right": "awsui_t-right_18wu0_1pq8u_516",
  "t-center": "awsui_t-center_18wu0_1pq8u_520",
  "p-n": "awsui_p-n_18wu0_1pq8u_675",
  "p-top-n": "awsui_p-top-n_18wu0_1pq8u_680",
  "p-vertical-n": "awsui_p-vertical-n_18wu0_1pq8u_681",
  "p-right-n": "awsui_p-right-n_18wu0_1pq8u_685",
  "p-horizontal-n": "awsui_p-horizontal-n_18wu0_1pq8u_686",
  "p-bottom-n": "awsui_p-bottom-n_18wu0_1pq8u_690",
  "p-left-n": "awsui_p-left-n_18wu0_1pq8u_695",
  "p-xxxs": "awsui_p-xxxs_18wu0_1pq8u_700",
  "p-top-xxxs": "awsui_p-top-xxxs_18wu0_1pq8u_705",
  "p-vertical-xxxs": "awsui_p-vertical-xxxs_18wu0_1pq8u_706",
  "p-right-xxxs": "awsui_p-right-xxxs_18wu0_1pq8u_710",
  "p-horizontal-xxxs": "awsui_p-horizontal-xxxs_18wu0_1pq8u_711",
  "p-bottom-xxxs": "awsui_p-bottom-xxxs_18wu0_1pq8u_715",
  "p-left-xxxs": "awsui_p-left-xxxs_18wu0_1pq8u_720",
  "p-xxs": "awsui_p-xxs_18wu0_1pq8u_725",
  "p-top-xxs": "awsui_p-top-xxs_18wu0_1pq8u_730",
  "p-vertical-xxs": "awsui_p-vertical-xxs_18wu0_1pq8u_731",
  "p-right-xxs": "awsui_p-right-xxs_18wu0_1pq8u_735",
  "p-horizontal-xxs": "awsui_p-horizontal-xxs_18wu0_1pq8u_736",
  "p-bottom-xxs": "awsui_p-bottom-xxs_18wu0_1pq8u_740",
  "p-left-xxs": "awsui_p-left-xxs_18wu0_1pq8u_745",
  "p-xs": "awsui_p-xs_18wu0_1pq8u_750",
  "p-top-xs": "awsui_p-top-xs_18wu0_1pq8u_755",
  "p-vertical-xs": "awsui_p-vertical-xs_18wu0_1pq8u_756",
  "p-right-xs": "awsui_p-right-xs_18wu0_1pq8u_760",
  "p-horizontal-xs": "awsui_p-horizontal-xs_18wu0_1pq8u_761",
  "p-bottom-xs": "awsui_p-bottom-xs_18wu0_1pq8u_765",
  "p-left-xs": "awsui_p-left-xs_18wu0_1pq8u_770",
  "p-s": "awsui_p-s_18wu0_1pq8u_775",
  "p-top-s": "awsui_p-top-s_18wu0_1pq8u_780",
  "p-vertical-s": "awsui_p-vertical-s_18wu0_1pq8u_781",
  "p-right-s": "awsui_p-right-s_18wu0_1pq8u_785",
  "p-horizontal-s": "awsui_p-horizontal-s_18wu0_1pq8u_786",
  "p-bottom-s": "awsui_p-bottom-s_18wu0_1pq8u_790",
  "p-left-s": "awsui_p-left-s_18wu0_1pq8u_795",
  "p-m": "awsui_p-m_18wu0_1pq8u_800",
  "p-top-m": "awsui_p-top-m_18wu0_1pq8u_805",
  "p-vertical-m": "awsui_p-vertical-m_18wu0_1pq8u_806",
  "p-right-m": "awsui_p-right-m_18wu0_1pq8u_810",
  "p-horizontal-m": "awsui_p-horizontal-m_18wu0_1pq8u_811",
  "p-bottom-m": "awsui_p-bottom-m_18wu0_1pq8u_815",
  "p-left-m": "awsui_p-left-m_18wu0_1pq8u_820",
  "p-l": "awsui_p-l_18wu0_1pq8u_695",
  "p-top-l": "awsui_p-top-l_18wu0_1pq8u_830",
  "p-vertical-l": "awsui_p-vertical-l_18wu0_1pq8u_831",
  "p-right-l": "awsui_p-right-l_18wu0_1pq8u_835",
  "p-horizontal-l": "awsui_p-horizontal-l_18wu0_1pq8u_836",
  "p-bottom-l": "awsui_p-bottom-l_18wu0_1pq8u_840",
  "p-left-l": "awsui_p-left-l_18wu0_1pq8u_845",
  "p-xl": "awsui_p-xl_18wu0_1pq8u_850",
  "p-top-xl": "awsui_p-top-xl_18wu0_1pq8u_855",
  "p-vertical-xl": "awsui_p-vertical-xl_18wu0_1pq8u_856",
  "p-right-xl": "awsui_p-right-xl_18wu0_1pq8u_860",
  "p-horizontal-xl": "awsui_p-horizontal-xl_18wu0_1pq8u_861",
  "p-bottom-xl": "awsui_p-bottom-xl_18wu0_1pq8u_865",
  "p-left-xl": "awsui_p-left-xl_18wu0_1pq8u_870",
  "p-xxl": "awsui_p-xxl_18wu0_1pq8u_875",
  "p-top-xxl": "awsui_p-top-xxl_18wu0_1pq8u_880",
  "p-vertical-xxl": "awsui_p-vertical-xxl_18wu0_1pq8u_881",
  "p-right-xxl": "awsui_p-right-xxl_18wu0_1pq8u_885",
  "p-horizontal-xxl": "awsui_p-horizontal-xxl_18wu0_1pq8u_886",
  "p-bottom-xxl": "awsui_p-bottom-xxl_18wu0_1pq8u_890",
  "p-left-xxl": "awsui_p-left-xxl_18wu0_1pq8u_895",
  "p-xxxl": "awsui_p-xxxl_18wu0_1pq8u_900",
  "p-top-xxxl": "awsui_p-top-xxxl_18wu0_1pq8u_905",
  "p-vertical-xxxl": "awsui_p-vertical-xxxl_18wu0_1pq8u_906",
  "p-right-xxxl": "awsui_p-right-xxxl_18wu0_1pq8u_910",
  "p-horizontal-xxxl": "awsui_p-horizontal-xxxl_18wu0_1pq8u_911",
  "p-bottom-xxxl": "awsui_p-bottom-xxxl_18wu0_1pq8u_915",
  "p-left-xxxl": "awsui_p-left-xxxl_18wu0_1pq8u_920",
  "m-n": "awsui_m-n_18wu0_1pq8u_925",
  "m-top-n": "awsui_m-top-n_18wu0_1pq8u_930",
  "m-vertical-n": "awsui_m-vertical-n_18wu0_1pq8u_931",
  "m-right-n": "awsui_m-right-n_18wu0_1pq8u_935",
  "m-horizontal-n": "awsui_m-horizontal-n_18wu0_1pq8u_936",
  "m-bottom-n": "awsui_m-bottom-n_18wu0_1pq8u_940",
  "m-left-n": "awsui_m-left-n_18wu0_1pq8u_945",
  "m-xxxs": "awsui_m-xxxs_18wu0_1pq8u_950",
  "m-top-xxxs": "awsui_m-top-xxxs_18wu0_1pq8u_955",
  "m-vertical-xxxs": "awsui_m-vertical-xxxs_18wu0_1pq8u_956",
  "m-right-xxxs": "awsui_m-right-xxxs_18wu0_1pq8u_960",
  "m-horizontal-xxxs": "awsui_m-horizontal-xxxs_18wu0_1pq8u_961",
  "m-bottom-xxxs": "awsui_m-bottom-xxxs_18wu0_1pq8u_965",
  "m-left-xxxs": "awsui_m-left-xxxs_18wu0_1pq8u_970",
  "m-xxs": "awsui_m-xxs_18wu0_1pq8u_975",
  "m-top-xxs": "awsui_m-top-xxs_18wu0_1pq8u_980",
  "m-vertical-xxs": "awsui_m-vertical-xxs_18wu0_1pq8u_981",
  "m-right-xxs": "awsui_m-right-xxs_18wu0_1pq8u_985",
  "m-horizontal-xxs": "awsui_m-horizontal-xxs_18wu0_1pq8u_986",
  "m-bottom-xxs": "awsui_m-bottom-xxs_18wu0_1pq8u_990",
  "m-left-xxs": "awsui_m-left-xxs_18wu0_1pq8u_995",
  "m-xs": "awsui_m-xs_18wu0_1pq8u_1000",
  "m-top-xs": "awsui_m-top-xs_18wu0_1pq8u_1005",
  "m-vertical-xs": "awsui_m-vertical-xs_18wu0_1pq8u_1006",
  "m-right-xs": "awsui_m-right-xs_18wu0_1pq8u_1010",
  "m-horizontal-xs": "awsui_m-horizontal-xs_18wu0_1pq8u_1011",
  "m-bottom-xs": "awsui_m-bottom-xs_18wu0_1pq8u_1015",
  "m-left-xs": "awsui_m-left-xs_18wu0_1pq8u_1020",
  "m-s": "awsui_m-s_18wu0_1pq8u_1025",
  "m-top-s": "awsui_m-top-s_18wu0_1pq8u_1030",
  "m-vertical-s": "awsui_m-vertical-s_18wu0_1pq8u_1031",
  "m-right-s": "awsui_m-right-s_18wu0_1pq8u_1035",
  "m-horizontal-s": "awsui_m-horizontal-s_18wu0_1pq8u_1036",
  "m-bottom-s": "awsui_m-bottom-s_18wu0_1pq8u_1040",
  "m-left-s": "awsui_m-left-s_18wu0_1pq8u_1045",
  "m-m": "awsui_m-m_18wu0_1pq8u_1050",
  "m-top-m": "awsui_m-top-m_18wu0_1pq8u_1055",
  "m-vertical-m": "awsui_m-vertical-m_18wu0_1pq8u_1056",
  "m-right-m": "awsui_m-right-m_18wu0_1pq8u_1060",
  "m-horizontal-m": "awsui_m-horizontal-m_18wu0_1pq8u_1061",
  "m-bottom-m": "awsui_m-bottom-m_18wu0_1pq8u_1065",
  "m-left-m": "awsui_m-left-m_18wu0_1pq8u_1070",
  "m-l": "awsui_m-l_18wu0_1pq8u_945",
  "m-top-l": "awsui_m-top-l_18wu0_1pq8u_1080",
  "m-vertical-l": "awsui_m-vertical-l_18wu0_1pq8u_1081",
  "m-right-l": "awsui_m-right-l_18wu0_1pq8u_1085",
  "m-horizontal-l": "awsui_m-horizontal-l_18wu0_1pq8u_1086",
  "m-bottom-l": "awsui_m-bottom-l_18wu0_1pq8u_1090",
  "m-left-l": "awsui_m-left-l_18wu0_1pq8u_1095",
  "m-xl": "awsui_m-xl_18wu0_1pq8u_1100",
  "m-top-xl": "awsui_m-top-xl_18wu0_1pq8u_1105",
  "m-vertical-xl": "awsui_m-vertical-xl_18wu0_1pq8u_1106",
  "m-right-xl": "awsui_m-right-xl_18wu0_1pq8u_1110",
  "m-horizontal-xl": "awsui_m-horizontal-xl_18wu0_1pq8u_1111",
  "m-bottom-xl": "awsui_m-bottom-xl_18wu0_1pq8u_1115",
  "m-left-xl": "awsui_m-left-xl_18wu0_1pq8u_1120",
  "m-xxl": "awsui_m-xxl_18wu0_1pq8u_1125",
  "m-top-xxl": "awsui_m-top-xxl_18wu0_1pq8u_1130",
  "m-vertical-xxl": "awsui_m-vertical-xxl_18wu0_1pq8u_1131",
  "m-right-xxl": "awsui_m-right-xxl_18wu0_1pq8u_1135",
  "m-horizontal-xxl": "awsui_m-horizontal-xxl_18wu0_1pq8u_1136",
  "m-bottom-xxl": "awsui_m-bottom-xxl_18wu0_1pq8u_1140",
  "m-left-xxl": "awsui_m-left-xxl_18wu0_1pq8u_1145",
  "m-xxxl": "awsui_m-xxxl_18wu0_1pq8u_1150",
  "m-top-xxxl": "awsui_m-top-xxxl_18wu0_1pq8u_1155",
  "m-vertical-xxxl": "awsui_m-vertical-xxxl_18wu0_1pq8u_1156",
  "m-right-xxxl": "awsui_m-right-xxxl_18wu0_1pq8u_1160",
  "m-horizontal-xxxl": "awsui_m-horizontal-xxxl_18wu0_1pq8u_1161",
  "m-bottom-xxxl": "awsui_m-bottom-xxxl_18wu0_1pq8u_1165",
  "m-left-xxxl": "awsui_m-left-xxxl_18wu0_1pq8u_1170",
  "d-block": "awsui_d-block_18wu0_1pq8u_1175",
  "d-inline": "awsui_d-inline_18wu0_1pq8u_1178",
  "d-inline-block": "awsui_d-inline-block_18wu0_1pq8u_1181",
  "d-none": "awsui_d-none_18wu0_1pq8u_1184",
  "f-left": "awsui_f-left_18wu0_1pq8u_1188",
  "f-right": "awsui_f-right_18wu0_1pq8u_1192"
};
  