import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadFileUpload } from '../common/RadFileUpload'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'
import { toBase64 } from '../common/utilities'

export function DataAssetEdit () {
  const navigate = useNavigate()
  const { dataAssetId } = useParams()
  const [files, setFiles] = useState([])
  const [formValues, setFormValues] = useState()
  const { data: dataAsset } = useGet(dataAssetId ? `/api/data-asset/${dataAssetId}` : null)
  const { data: dataSourceOptions } = useGet('/api/option/data-source?required=true')
  const { data: governanceIndicatorOptions } = useGet('/api/option/type?entity=governance_indicator&required=true')
  const { data: formatOptions } = useGet('/api/option/type?entity=data_format&required=true')
  const { data: personOptions } = useGet('/api/option/person')
  const { data: statusOptions } = useGet('/api/option/type?entity=data_asset_status&required=true')
  const create = usePost('/api/data-asset', formValues, (resp) => { navigate(`/data-asset/${resp.id}`) })
  const update = usePut(`/api/data-asset/${dataAssetId}`, formValues, (resp) => { navigate(`/data-asset/${dataAssetId}`) })

  useEffect(() => {
    const defaultFormValues = { dataConsumers: [], dataSources: [], governanceIndicators: [] }
    setFormValues(dataAsset ?? defaultFormValues)
    if (dataAsset?.yamlFile != null) {
      const file = new File([], dataAsset.yamlFile.path.split('/').pop(), {
        type: dataAsset.yamlFile.type,
        lastModifiedDate: dataAsset.yamlFile.updatedAt
      })
      setFiles([file])
    }
  }, [dataAsset])

  async function cancel () {
    if (dataAssetId != null) {
      navigate(`/data-asset/${dataAssetId}`)
    } else {
      navigate('/data-asset')
    }
  }

  async function saveChanges () {
    if (dataAssetId != null) {
      update()
    } else {
      create()
    }
  }

  if (
    formValues != null &&
    dataSourceOptions != null &&
    formatOptions != null &&
    personOptions != null &&
    governanceIndicatorOptions != null &&
    statusOptions != null
  ) {
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>{dataAssetId ? 'Edit' : 'New'} Data Asset</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Name *' field='name'>
                      <RadInput
                        ariaRequired
                        placeholder='Enter name'
                        value={formValues.name}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Description *' field='description'>
                      <RadTextarea
                        placeholder='Enter description'
                        value={formValues.description}
                        onChange={({ detail }) => setFormValues({ ...formValues, description: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Location *' field='location'>
                      <RadTextarea
                        placeholder='Enter location'
                        value={formValues.location}
                        onChange={({ detail }) => setFormValues({ ...formValues, location: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Format *' field='formatTypeId'>
                      <RadSelect
                        selectedOption={formValues.formatTypeId ? formatOptions.find(x => x.value === formValues.formatTypeId?.toString()) : null}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, formatTypeId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, formatTypeId: null })
                          }
                        }}
                        options={formatOptions}
                        selectedAriaLabel='Selected'
                        placeholder='Choose a format'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Status *' field='statusTypeId'>
                      <RadSelect
                        selectedOption={formValues.statusTypeId ? statusOptions.find(x => x.value === formValues.statusTypeId?.toString()) : null}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, statusTypeId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, statusTypeId: null })
                          }
                        }}
                        options={statusOptions}
                        selectedAriaLabel='Selected'
                        placeholder='Choose a status'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Date Created *' field='createdDate'>
                      <RadInput
                        ariaRequired
                        type='date'
                        placeholder='Enter date created'
                        value={formValues.createdDate}
                        onChange={({ detail }) => setFormValues({ ...formValues, createdDate: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='YAML File' field='yamlFileId'>
                      <RadFileUpload
                        onChange={async ({ detail }) => {
                          setFiles(detail.value)
                          if (detail.value[0] != null) {
                            const yamlFile = {
                              name: detail.value[0].name,
                              type: detail.value[0].type
                            }
                            yamlFile.data = await toBase64(detail.value[0])
                            setFormValues({ ...formValues, yamlFile })
                          } else {
                            setFormValues({ ...formValues, yamlFile: null })
                          }
                        }}
                        value={files}
                        accept='application/x-yaml'
                        i18nStrings={{
                          uploadButtonText: e => e ? 'Choose files' : 'Choose file',
                          dropzoneText: e => e ? 'Drop files to upload' : 'Drop file to upload',
                          removeFileAriaLabel: e => `Remove file ${e + 1}`,
                          limitShowFewer: 'Show fewer files',
                          limitShowMore: 'Show more files',
                          errorIconAriaLabel: 'Error'
                        }}
                        showFileThumbnail={false}
                        tokenLimit={1}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Data Sources *</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const dataSources = formValues.dataSources
                      dataSources.push({ uuid: uuidv4(), primaryEmployer: false })
                      setFormValues({ ...formValues, dataSources })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const dataSources = [...formValues.dataSources]
                      dataSources.splice(itemIndex, 1)
                      setFormValues({ ...formValues, dataSources })
                    }}
                    items={formValues.dataSources}
                    addButtonText='Add new data source'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const filteredOptions = dataSourceOptions.filter((x) => !formValues.dataSources.map((y) => y.dataSourceId?.toString()).includes(x.value))
                          return (
                            <RadFormField field={`dataSource.${item.id ?? item.uuid}.dataSourceId`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={dataSourceOptions.find(x => x.value === item.dataSourceId?.toString())}
                                onChange={({ detail }) => {
                                  const dataSources = formValues.dataSources
                                  item.dataSourceId = parseInt(detail.selectedOption.value)
                                  setFormValues({ ...formValues, dataSources })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a data source'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No data sources added to this data asset.'
                  />
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Data Consumers</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const dataConsumers = formValues.dataConsumers
                      dataConsumers.push({ uuid: uuidv4(), primaryEmployer: false })
                      setFormValues({ ...formValues, dataConsumers })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const dataConsumers = [...formValues.dataConsumers]
                      dataConsumers.splice(itemIndex, 1)
                      setFormValues({ ...formValues, dataConsumers })
                    }}
                    items={formValues.dataConsumers}
                    addButtonText='Add new data consumer'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const filteredOptions = personOptions.filter((x) => !formValues.dataConsumers.map((y) => y.personId?.toString()).includes(x.value))
                          const person = formValues.dataConsumers.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                          return (
                            <RadFormField field={`dataConsumer.${item.id ?? item.uuid}.personId`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={personOptions.find(x => x.value === item.personId?.toString())}
                                onChange={({ detail }) => {
                                  const dataConsumers = formValues.dataConsumers
                                  person.personId = parseInt(detail.selectedOption.value)
                                  setFormValues({ ...formValues, dataConsumers })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a person'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No data consumers added to this data asset.'
                  />
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Governance Indicators</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const governanceIndicators = formValues.governanceIndicators
                      governanceIndicators.push({ uuid: uuidv4(), governanceIndicators: {} })
                      setFormValues({ ...formValues, governanceIndicators })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const governanceIndicators = [...formValues.governanceIndicators]
                      governanceIndicators.splice(itemIndex, 1)
                      setFormValues({ ...formValues, governanceIndicators })
                    }}
                    items={formValues.governanceIndicators}
                    addButtonText='Add new governance indicator'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const filteredOptions = (governanceIndicatorOptions ?? []).filter((x) => !formValues.governanceIndicators.map((y) => y.typeId).includes(parseInt(x.value)))
                          return (
                            <RadFormField field={`governanceIndicator.${item.id ?? item.uuid}.typeId`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={governanceIndicatorOptions.find(x => x.value === item.typeId?.toString())}
                                onChange={({ detail }) => {
                                  const governanceIndicators = formValues.governanceIndicators
                                  item.id = null
                                  item.typeId = parseInt(detail.selectedOption.value)
                                  item.name = detail.selectedOption.label
                                  setFormValues({ ...formValues, governanceIndicators })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a governance indicator'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      }

                    ]}
                    removeButtonText='Remove'
                    empty='No governance indicators added to this data asset.'
                  />
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
