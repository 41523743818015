
    import './styles.scoped.css';
    export default {
  "icon": "awsui_icon_h11ix_1uzwp_188",
  "icon-flex-height": "awsui_icon-flex-height_h11ix_1uzwp_194",
  "size-small": "awsui_size-small_h11ix_1uzwp_219",
  "size-small-mapped-height": "awsui_size-small-mapped-height_h11ix_1uzwp_223",
  "size-normal": "awsui_size-normal_h11ix_1uzwp_238",
  "size-normal-mapped-height": "awsui_size-normal-mapped-height_h11ix_1uzwp_242",
  "size-medium": "awsui_size-medium_h11ix_1uzwp_257",
  "size-medium-mapped-height": "awsui_size-medium-mapped-height_h11ix_1uzwp_261",
  "size-big": "awsui_size-big_h11ix_1uzwp_276",
  "size-big-mapped-height": "awsui_size-big-mapped-height_h11ix_1uzwp_280",
  "size-large": "awsui_size-large_h11ix_1uzwp_295",
  "size-large-mapped-height": "awsui_size-large-mapped-height_h11ix_1uzwp_299",
  "variant-normal": "awsui_variant-normal_h11ix_1uzwp_314",
  "variant-disabled": "awsui_variant-disabled_h11ix_1uzwp_317",
  "variant-inverted": "awsui_variant-inverted_h11ix_1uzwp_320",
  "variant-subtle": "awsui_variant-subtle_h11ix_1uzwp_323",
  "variant-warning": "awsui_variant-warning_h11ix_1uzwp_326",
  "variant-error": "awsui_variant-error_h11ix_1uzwp_329",
  "variant-success": "awsui_variant-success_h11ix_1uzwp_332",
  "variant-link": "awsui_variant-link_h11ix_1uzwp_335",
  "name-angle-left-double": "awsui_name-angle-left-double_h11ix_1uzwp_338",
  "name-angle-left": "awsui_name-angle-left_h11ix_1uzwp_338",
  "name-angle-right-double": "awsui_name-angle-right-double_h11ix_1uzwp_340",
  "name-angle-right": "awsui_name-angle-right_h11ix_1uzwp_340",
  "name-arrow-left": "awsui_name-arrow-left_h11ix_1uzwp_342",
  "name-caret-left-filled": "awsui_name-caret-left-filled_h11ix_1uzwp_343",
  "name-caret-right-filled": "awsui_name-caret-right-filled_h11ix_1uzwp_344",
  "name-audio-full": "awsui_name-audio-full_h11ix_1uzwp_345",
  "name-audio-half": "awsui_name-audio-half_h11ix_1uzwp_346",
  "name-audio-off": "awsui_name-audio-off_h11ix_1uzwp_347",
  "name-external": "awsui_name-external_h11ix_1uzwp_348",
  "name-redo": "awsui_name-redo_h11ix_1uzwp_349",
  "name-shrink": "awsui_name-shrink_h11ix_1uzwp_350",
  "name-undo": "awsui_name-undo_h11ix_1uzwp_351",
  "name-view-vertical": "awsui_name-view-vertical_h11ix_1uzwp_352",
  "badge": "awsui_badge_h11ix_1uzwp_356"
};
  