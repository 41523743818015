import moment from 'moment-timezone'
moment.tz.setDefault('America/Chicago')

export const removeEmptyStrings = (obj) => {
  const isArray = Array.isArray(obj)
  for (const k of Object.keys(obj)) {
    if (obj[k] !== null && obj[k] !== undefined && typeof obj[k] === 'string' && obj[k].trim() === '') {
      obj[k] = null
    } else if (obj[k] !== null && obj[k] !== undefined && typeof obj[k] === 'object') {
      removeEmptyStrings(obj[k])
    }
    if (isArray && obj.length === k) {
      removeEmptyStrings(obj)
    }
  }
  return obj
}

export function getDownloadUrl (folder, id, path) {
  if (path == null) return null
  const customDomain = window.location.hostname.split('.').pop() !== 'app'
  const root = customDomain
    ? window.location.hostname.split('.')[0]
    : window.location.hostname.split('-')[0]
  switch (root) {
    case 'localhost':
      return 'http://localhost:3001/api/storage/' + path
    case 'smts':
      return 'https://smts.asem.io/api/storage/' + path
    default:
      return customDomain
        ? `https://${root}.smts.asem.io/api/storage/` + path
        : `https://${root}-app-p7bmo673iq-uc.a.run.app/api/storage/` + path
  }
}

export function getShortText (text) {
  if (text == null) return text
  let results = ''
  const words = text.split(' ')
  while (results.length < 240 && words.length > 0) {
    results += words.splice(0, 1) + ' '
  }
  if (results.length < text.length) results += ' ...'
  return results
}

export function formatDate (inputString, format = 'MM/DD/YYYY') {
  if (inputString == null) {
    return null
  }
  return moment(inputString).format(format)
}

export function formatTime (inputString) {
  if (inputString == null) {
    return null
  }
  return moment(inputString).format('h:mm A')
}

export function formatDateTime (inputString) {
  if (inputString == null) {
    return null
  }
  return moment(inputString).format('MM/DD/YYYY h:mm A')
}

export function getEnvironmentURL () {
  let environmentURL
  switch (window.location.hostname.split('.')[0]) {
    case 'production':
      environmentURL = 'https://organizationalhealth.arrowheadconsulting.com'
      break
    case 'uat':
      environmentURL = 'https://uat.organizationalhealth.arrowheadconsulting.com'
      break
    case 'qa':
      environmentURL = 'https://qa.organizationalhealth.arrowheadconsulting.com'
      break
    default:
      environmentURL = 'http://localhost:3000'
  }
  return environmentURL
}

export function getOrdinal (number) {
  switch (number) {
    case undefined:
      return ''
    case 1:
      return '1st'
    case 2:
      return '2nd'
    case 3:
      return '3rd'
    default:
      return `${number}th`
  }
}

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
  reader.readAsDataURL(file)
})

export function toTitleCase (str) {
  return str
    .split('_')
    .filter(x => x.length > 0)
    .map((x) => (x.charAt(0).toUpperCase() + x.slice(1)))
    .join(' ')
}

export function jsonToFormData (json) {
  const formData = new FormData()
  for (const file of json.files ?? []) {
    formData.append('file', file, file.name)
  }
  const clone = Object.assign({}, json)
  formData.append('values', JSON.stringify(removeEmptyStrings(clone)))
  return formData
}
