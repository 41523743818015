import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadBox } from '../common/RadBox'
import { RadLink } from '../common/RadLink'
import { formatDate } from '../common/utilities'
import { RadBadgeGroup } from '../common/RadBadgeGroup'

export function CollaborativeDetail () {
  const navigate = useNavigate()
  const { collaborativeId } = useParams()
  const { data: collaborative } = useGet(`/api/collaborative/${collaborativeId}`)
  const confirmDelete = useConfirm('Delete collaborative?', 'Delete collaborative permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/collaborative/${collaborativeId}`, () => { navigate('/collaborative') })

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (collaborative != null) {
    return (
      <RadAppLayout
        name={collaborative.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={edit}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={collaborative.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
            description={collaborative.description}
          >
            {collaborative.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadSpaceBetween size='l'>
                <RadColumnLayout columns={4} borders='vertical'>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Established
                    </RadBox>
                    <div>{collaborative.established != null ? formatDate(collaborative.established) : '-'}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Stage
                    </RadBox>
                    <div>{collaborative.stage?.name ?? '-'}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Goal
                    </RadBox>
                    <div>{collaborative.goal ?? '-'}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Metrics
                    </RadBox>
                    <div>{collaborative.metrics ?? '-'}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      People
                    </RadBox>
                    <div>{collaborative.people.length > 0 ? collaborative.people.map((x) => <div key={x.id}><RadLink href={`/person/${x.person.id}`}>{x.person.fullName}</RadLink></div>) : '-'}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Organizations
                    </RadBox>
                    <div>{collaborative.organizations.length > 0 ? collaborative.organizations.map((x) => <div key={x.id}><RadLink href={`/organization/${x.organization.id}`}>{x.organization.name}</RadLink></div>) : '-'}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Insights
                    </RadBox>
                    <div>{collaborative.insights.length > 0
                      ? collaborative.insights.map((x) => <div key={x.id}><RadLink href={`/insight/${x.insight.id}`}>{x.insight.name}</RadLink></div>)
                      : '-'}
                    </div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Sectors
                    </RadBox>
                    <div>
                      <RadBadgeGroup
                        parentKey={collaborativeId}
                        items={collaborative.sectors}
                        mapFunc={x => ({ key: x.id, name: x.sector.name })}
                      />
                    </div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Tags
                    </RadBox>
                    <div>
                      <RadBadgeGroup
                        parentKey={collaborativeId}
                        items={collaborative.tags}
                        mapFunc={x => ({ key: x.id, name: x.tag.name })}
                      />
                    </div>
                  </div>
                </RadColumnLayout>
              </RadSpaceBetween>
            </RadContainer>
          </RadSpaceBetween>
        }
      />
    )
  }
}
