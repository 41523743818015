import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadHeader } from '../common/RadHeader'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadInput } from '../common/RadInput'
import { RadButton } from '../common/RadButton'

export function SectorEdit () {
  const navigate = useNavigate()
  const { sectorId } = useParams()
  const { data: sector } = useGet(sectorId ? `/api/sector/${sectorId}` : null)
  const [formValues, setFormValues] = useState()
  const create = usePost('/api/sector', formValues, (resp) => { navigate('/sector') })
  const update = usePut(`/api/sector/${sectorId}`, formValues, () => { navigate('/sector') })

  useEffect(() => {
    const defaultFormValues = { }
    setFormValues(sector ?? defaultFormValues)
  }, [sector])

  async function cancel () {
    navigate('/sector')
  }

  async function saveChanges () {
    if (sectorId != null) { update() } else { create() }
  }

  if (formValues != null) {
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>
            {formValues.id ? 'Edit Sector' : 'New Sector'}
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadContainer>
                <RadSpaceBetween size='l'>
                  <RadFormField label='Name' field='name'>
                    <RadInput
                      ariaRequired
                      placeholder='Enter name'
                      value={formValues.name ?? ''}
                      onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                    />
                  </RadFormField>
                </RadSpaceBetween>
              </RadContainer>
            </RadForm>
          </form>
        }
      />
    )
  }
}
