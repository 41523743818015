import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBadgeGroup } from '../common/RadBadgeGroup'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput2 } from '../common/RadInput2'
import { RadLink } from '../common/RadLink'
import { RadMultiselect } from '../common/RadMultiselect'
import { RadPagination } from '../common/RadPagination'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { getShortText } from '../common/utilities'

export function CollaborativeList ({ selected }) {
  const pageLength = 20
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  // const [targetAreaFilteringText, setTargetAreaFilteringText] = useState('')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const [sectorIds, setSectorIds] = useState(searchParams.get('sectorIds') ?? '')
  // const [targetAreaIds, setTargetAreaIds] = useState(searchParams.get('targetAreaIds') ?? '')
  const [tagIds, setTagIds] = useState(searchParams.get('tagIds') ?? '')
  const { data: collaboratives, count } = useGet(`/api/collaborative?search=${searchText}&sectorIds=${sectorIds ?? ''}&tagIds=${tagIds ?? ''}&limit=${pageLength}&offset=${(currentPageIndex - 1) * pageLength}`)
  const { data: sectorOptions } = useGet('/api/option/sector')
  // const { data: targetAreaOptions } = useGet(`/api/option/targetArea?search=${targetAreaFilteringText}`)
  const { data: tags } = useGet('/api/tag?limit=2000')

  if (
    collaboratives != null &&
    sectorOptions != null &&
    // targetAreaOptions != null &&
    tags != null
  ) {
    const tagOptions = tags.map((x) => { return { value: x.id.toString(), label: x.name } })

    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('/collaborative/create')}>Create</RadButton>
              </RadSpaceBetween>
            }
            counter={'(' + count + ')'}
          >
            Collaboratives
          </RadHeader>
        }
        content={
          <RadCards
            cardDefinition={{
              header: item => <RadLink fontSize='heading-l' href={`/collaborative/${item.id}`}>{item.name}</RadLink>,
              sections: [
                {
                  id: 'description',
                  content: item => getShortText(item.description) ?? ''
                },
                {
                  id: 'sectors',
                  header: 'Sectors',
                  content: item =>
                    <RadBadgeGroup
                      parentKey={item.id}
                      items={item.sectors}
                      mapFunc={x => ({ key: x.id, name: x.name })}
                    />
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={collaboratives}
            variant='full-page'
            filter={
              <RadGrid
                gridDefinition={[
                  { colspan: { default: 12, xs: 12 } },
                  { colspan: { default: 12, xs: 4 } },
                  { colspan: { default: 12, xs: 4 } },
                  { colspan: { default: 12, xs: 4 } }
                ]}
              >
                <RadInput2
                  initialValue={searchText}
                  placeholder='Search'
                  type='search'
                  onEnter={({ detail }) => {
                    setSearchText(detail.value)
                    if (detail.value) {
                      searchParams.set('search', detail.value)
                    } else {
                      searchParams.delete('search')
                    }
                    setSearchParams(searchParams)
                  }}
                />
                <RadMultiselect
                  selectedOptions={sectorOptions.filter((x) => sectorIds.split('-').includes(x.value))}
                  onChange={({ detail }) => {
                    const ids = detail.selectedOptions.map((x) => x.value).join('-')
                    if (ids === '') {
                      searchParams.delete('sectorIds')
                    } else {
                      searchParams.set('sectorIds', ids)
                    }
                    setSectorIds(ids)
                    searchParams.set('page', 1)
                    setCurrentPageIndex(1)
                    setSearchParams(searchParams)
                  }}
                  options={sectorOptions}
                  selectedAriaLabel='Selected'
                  deselectAriaLabel={e => `Remove ${e.label}`}
                  placeholder='Choose sectors'
                  keepOpen={false}
                />
                <RadMultiselect
                  filteringType='auto'
                  selectedOptions={tagOptions.filter((x) => tagIds.split('-').includes(x.value))}
                  onChange={({ detail }) => {
                    const ids = detail.selectedOptions.map((x) => x.value).join('-')
                    if (ids === '') {
                      searchParams.delete('tagIds')
                    } else {
                      searchParams.set('tagIds', ids)
                    }
                    setTagIds(ids)
                    searchParams.set('page', 1)
                    setCurrentPageIndex(1)
                    setSearchParams(searchParams)
                  }}
                  options={tagOptions}
                  selectedAriaLabel='Selected'
                  deselectAriaLabel={e => `Remove ${e.label}`}
                  placeholder='Choose tags'
                  keepOpen={false}
                />
                {/* <RadMultiselect
                  filteringType='manual'
                  selectedOptions={targetAreaOptions.filter((x) => targetAreaIds.split('-').includes(x.value))}
                  onChange={({ detail }) => {
                    const ids = detail.selectedOptions.map((x) => x.value).join('-')
                    if (ids === '') {
                      searchParams.delete('targetAreaIds')
                    } else {
                      searchParams.set('targetAreaIds', ids)
                    }
                    setTargetAreaIds(ids)
                    searchParams.set('page', 1)
                    setCurrentPageIndex(1)
                    setSearchParams(searchParams)
                  }}
                  options={targetAreaOptions}
                  onLoadItems={({ detail }) => {
                    setTargetAreaFilteringText(detail.filteringText)
                  }}
                  selectedAriaLabel='Selected'
                  deselectAriaLabel={e => `Remove ${e.label}`}
                  placeholder='Choose target areas'
                  keepOpen={false}
                /> */}
              </RadGrid>
            }
            pagination={
              <RadPagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                  setCurrentPageIndex(detail.currentPageIndex)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            }
            empty={
              <RadBox textAlign='center' color='inherit'>
                No matches found.
              </RadBox>
            }
          />
        }
      />
    )
  }
}
