import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadCards } from '../common/RadCards'
import { RadLink } from '../common/RadLink'
import { RadButton } from '../common/RadButton'

export function SectorList () {
  const navigate = useNavigate()
  const { data: sectors, count } = useGet('/api/sector')

  if (sectors != null) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('/sector/create')}>Create</RadButton>
              </RadSpaceBetween>
            }
            counter={'(' + count + ')'}
          >
            Sectors
          </RadHeader>
        }
        content={
          <RadCards
            ariaLabels={{
              itemSelectionLabel: (e, t) => `select ${t.name}`,
              selectionGroupLabel: 'Item selection'
            }}
            cardDefinition={{
              header: item => <RadLink fontSize='heading-m' href={`/sector/${item.id}/edit`}>{item.name}</RadLink>,
              sections: [
                {
                  id: 'entities',
                  content: item =>
                    <RadSpaceBetween>
                      <RadLink href={`/collaborative?sectorIds=${item.id}`}>Collaboratives ({item.collaborativeCount})</RadLink>
                      <RadLink href={`/organization?sectorIds=${item.id}`}>Organizations ({item.organizationCount})</RadLink>
                    </RadSpaceBetween>
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={sectors}
            variant='full-page'
          />
        }
      />
    )
  }
}
