import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput2 } from '../common/RadInput2'
import { RadLink } from '../common/RadLink'
import { RadMultiselect } from '../common/RadMultiselect'
import { RadPagination } from '../common/RadPagination'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { getShortText } from '../common/utilities'
import { RadBadgeGroup } from '../common/RadBadgeGroup'

export function OrganizationList ({ selected }) {
  const pageLength = 20
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const [sectorIds, setSectorIds] = useState(searchParams.get('sectorIds') ?? '')
  const [tagIds, setTagIds] = useState(searchParams.get('tagIds') ?? '')
  const [typeIds, setTypeIds] = useState(searchParams.get('typeIds') ?? '')
  const { data: organizations, count } = useGet(`/api/organization?search=${searchText}&sectorIds=${sectorIds ?? ''}&tagIds=${tagIds ?? ''}&typeIds=${typeIds ?? ''}&limit=${pageLength}&offset=${(currentPageIndex - 1) * pageLength}`)
  const { data: sectorOptions } = useGet('/api/option/sector')
  const { data: typeOptions } = useGet('/api/option/type?entity=organization&required=true')
  const { data: tags } = useGet('/api/tag?limit=2000')

  if (
    organizations != null &&
    sectorOptions != null &&
    tags != null &&
    typeOptions != null
  ) {
    const tagOptions = tags.map((x) => { return { value: x.id.toString(), label: x.name } })

    return (
      <RadAppLayout
        contentType='cards'
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('/organization/create')}>Create</RadButton>
              </RadSpaceBetween>
            }
            counter={'(' + count + ')'}
          >
            Organizations
          </RadHeader>
        }
        content={
          <RadCards
            stickyHeader
            cardDefinition={{
              header: item => <RadLink fontSize='heading-l' href={`/organization/${item.id}`}>{item.name}</RadLink>,
              sections: [
                {
                  id: 'description',
                  content: item => getShortText(item.description)
                },
                {
                  id: 'type',
                  header: 'Type',
                  content: item => item.type?.name ?? '-'
                },
                {
                  id: 'sectors',
                  header: 'Sectors',
                  content: item =>
                    <RadBadgeGroup
                      parentKey={item.id}
                      items={item.sectors}
                      mapFunc={x => ({ key: x.id, name: x.name })}
                    />
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={organizations}
            variant='full-page'
            filter={
              <div>
                <RadGrid
                  gridDefinition={[
                    { colspan: { default: 12, xxs: 12, m: 6 } },
                    { colspan: { default: 12, xxs: 4, m: 2 } },
                    { colspan: { default: 12, xxs: 4, m: 2 } },
                    { colspan: { default: 12, xxs: 4, m: 2 } }
                  ]}
                >
                  <RadInput2
                    initialValue={searchText}
                    placeholder='Search'
                    type='search'
                    onEnter={({ detail }) => {
                      setSearchText(detail.value)
                      if (detail.value) {
                        searchParams.set('search', detail.value)
                      } else {
                        searchParams.delete('search')
                      }
                      setSearchParams(searchParams)
                    }}
                  />
                  <RadMultiselect
                    selectedOptions={sectorOptions.filter((x) => sectorIds.split('-').includes(x.value))}
                    onChange={({ detail }) => {
                      const ids = detail.selectedOptions.map((x) => x.value).join('-')
                      if (ids === '') {
                        searchParams.delete('sectorIds')
                      } else {
                        searchParams.set('sectorIds', ids)
                      }
                      setSectorIds(ids)
                      searchParams.set('page', 1)
                      setCurrentPageIndex(1)
                      setSearchParams(searchParams)
                    }}
                    options={sectorOptions}
                    selectedAriaLabel='Selected'
                    deselectAriaLabel={e => `Remove ${e.label}`}
                    placeholder='Choose sectors'
                    keepOpen={false}
                  />
                  <RadMultiselect
                    filteringType='auto'
                    selectedOptions={tagOptions.filter((x) => tagIds.split('-').includes(x.value))}
                    onChange={({ detail }) => {
                      const ids = detail.selectedOptions.map((x) => x.value).join('-')
                      if (ids === '') {
                        searchParams.delete('tagIds')
                      } else {
                        searchParams.set('tagIds', ids)
                      }
                      setTagIds(ids)
                      searchParams.set('page', 1)
                      setCurrentPageIndex(1)
                      setSearchParams(searchParams)
                    }}
                    options={tagOptions}
                    selectedAriaLabel='Selected'
                    deselectAriaLabel={e => `Remove ${e.label}`}
                    placeholder='Choose tags'
                    keepOpen={false}
                  />
                  <RadMultiselect
                    filteringType='auto'
                    selectedOptions={typeOptions.filter((x) => typeIds.split('-').includes(x.value))}
                    onChange={({ detail }) => {
                      const ids = detail.selectedOptions.map((x) => x.value).join('-')
                      if (ids === '') {
                        searchParams.delete('typeIds')
                      } else {
                        searchParams.set('typeIds', ids)
                      }
                      setTypeIds(ids)
                      searchParams.set('page', 1)
                      setCurrentPageIndex(1)
                      setSearchParams(searchParams)
                    }}
                    options={typeOptions}
                    selectedAriaLabel='Selected'
                    deselectAriaLabel={e => `Remove ${e.label}`}
                    placeholder='Choose types'
                    keepOpen={false}
                  />
                </RadGrid>
              </div>
            }
            pagination={
              <RadPagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                  setCurrentPageIndex(detail.currentPageIndex)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            }
            empty={
              <RadBox textAlign='center' color='inherit'>
                No matches found.
              </RadBox>
            }
          />
        }
      />
    )
  }
}
