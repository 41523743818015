import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'

export function DataSourceEdit () {
  const navigate = useNavigate()
  const { dataSourceId } = useParams()
  const [formValues, setFormValues] = useState()
  const { data: dataSource } = useGet(dataSourceId ? `/api/data-source/${dataSourceId}` : null)
  const { data: collaborativeOptions } = useGet('/api/option/collaborative')
  const { data: organizationOptions } = useGet('/api/option/organization')
  const { data: vendorOptions } = useGet('/api/option/vendor')
  const create = usePost('/api/data-source', formValues, (resp) => { navigate(`/data-source/${resp.id}`) })
  const update = usePut(`/api/data-source/${dataSourceId}`, formValues, (resp) => { navigate(`/data-source/${dataSourceId}`) })

  useEffect(() => {
    const defaultFormValues = { sourceSystem: {} }
    setFormValues(dataSource ?? defaultFormValues)
  }, [dataSource])

  async function cancel () {
    if (dataSourceId != null) {
      navigate(`/data-source/${dataSourceId}`)
    } else {
      navigate('/data-source')
    }
  }

  async function saveChanges () {
    if (dataSourceId != null) {
      update()
    } else {
      create()
    }
  }

  if (
    formValues != null &&
    collaborativeOptions != null &&
    organizationOptions != null &&
    vendorOptions != null
  ) {
    const products = vendorOptions.find(x => x.value === formValues.sourceSystem.vendorId?.toString())?.products ?? []
    const productOptions = products.map(x => ({ value: x.id.toString(), label: x.name }))
    const combinedOptions = [
      ...collaborativeOptions.map(x => ({ ...x, type: 'collaborative' })),
      ...organizationOptions.map(x => ({ ...x, type: 'organization' }))
    ].sort((a, b) => a.label.localeCompare(b.label))

    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>{dataSourceId ? 'Edit' : 'New'} Data Source</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Name *' field='name'>
                      <RadInput
                        ariaRequired
                        placeholder='Enter name'
                        value={formValues.name}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Description *' field='description'>
                      <RadTextarea
                        placeholder='Enter description'
                        value={formValues.description}
                        onChange={({ detail }) => setFormValues({ ...formValues, description: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Source System *' field='sourceSystemId' constraintText='Vendor - product providing access from the data steward'>
                      <RadGrid
                        gridDefinition={[
                          { colspan: { default: 12, xxs: 6 } },
                          { colspan: { default: 12, xxs: 6 } }
                        ]}
                      >
                        <RadSelect
                          filteringType='auto'
                          selectedOption={vendorOptions.find(x => x.value === formValues.sourceSystem.vendorId?.toString())}
                          onChange={({ detail }) => {
                            const sourceSystem = formValues.sourceSystem
                            sourceSystem.vendorId = parseInt(detail.selectedOption.value)
                            setFormValues({ ...formValues, sourceSystem, sourceSystemId: null })
                          }}
                          options={vendorOptions}
                          enteredTextLabel={value => value}
                          selectedAriaLabel='Selected'
                          placeholder='Choose a vendor'
                          empty='No matches found'
                        />
                        <RadSelect
                          filteringType='auto'
                          selectedOption={productOptions.find(x => x.value === formValues.sourceSystemId?.toString())}
                          onChange={({ detail }) => {
                            setFormValues({ ...formValues, sourceSystemId: parseInt(detail.selectedOption.value) })
                          }}
                          options={productOptions}
                          enteredTextLabel={value => value}
                          selectedAriaLabel='Selected'
                          placeholder='Choose a product'
                          empty='No matches found'
                        />
                      </RadGrid>
                    </RadFormField>
                    <RadFormField label='Producer *' field='producerId' constraintText=''>
                      <RadSelect
                        filteringType='auto'
                        selectedOption={combinedOptions.find(x =>
                          (x.value === formValues.producerCollaborativeId?.toString() && x.type === 'collaborative') ||
                          (x.value === formValues.producerOrganizationId?.toString() && x.type === 'organization')
                        )}
                        onChange={({ detail }) => {
                          switch (detail.selectedOption.type) {
                            case 'collaborative':
                              setFormValues({ ...formValues, producerCollaborativeId: parseInt(detail.selectedOption.value), producerOrganizationId: null })
                              break
                            case 'organization':
                              setFormValues({ ...formValues, producerOrganizationId: parseInt(detail.selectedOption.value), producerCollaborativeId: null })
                              break
                            default:
                              break
                          }
                        }}
                        options={combinedOptions}
                        enteredTextLabel={value => value}
                        selectedAriaLabel='Selected'
                        placeholder='Choose a producer'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Steward *' field='stewardId' constraintText=''>
                      <RadSelect
                        filteringType='auto'
                        selectedOption={combinedOptions.find(x =>
                          (x.value === formValues.stewardCollaborativeId?.toString() && x.type === 'collaborative') ||
                          (x.value === formValues.stewardOrganizationId?.toString() && x.type === 'organization')
                        )}
                        onChange={({ detail }) => {
                          switch (detail.selectedOption.type) {
                            case 'collaborative':
                              setFormValues({ ...formValues, stewardCollaborativeId: parseInt(detail.selectedOption.value), stewardOrganizationId: null })
                              break
                            case 'organization':
                              setFormValues({ ...formValues, stewardOrganizationId: parseInt(detail.selectedOption.value), stewardCollaborativeId: null })
                              break
                            default:
                              break
                          }
                        }}
                        options={combinedOptions}
                        enteredTextLabel={value => value}
                        selectedAriaLabel='Selected'
                        placeholder='Choose a steward'
                        empty='No matches found'
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
