
    import './styles.scoped.css';
    export default {
  "dropdown-content-wrapper": "awsui_dropdown-content-wrapper_qwoo0_18n7e_151",
  "awsui-motion-fade-in-dropdown": "awsui_awsui-motion-fade-in-dropdown_qwoo0_18n7e_1",
  "refresh": "awsui_refresh_qwoo0_18n7e_172",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_qwoo0_18n7e_1",
  "root": "awsui_root_qwoo0_18n7e_197",
  "interior": "awsui_interior_qwoo0_18n7e_233",
  "dropdown": "awsui_dropdown_qwoo0_18n7e_151",
  "use-portal": "awsui_use-portal_qwoo0_18n7e_244",
  "is-empty": "awsui_is-empty_qwoo0_18n7e_296",
  "dropdown-drop-up": "awsui_dropdown-drop-up_qwoo0_18n7e_303",
  "with-limited-width": "awsui_with-limited-width_qwoo0_18n7e_307",
  "dropdown-drop-left": "awsui_dropdown-drop-left_qwoo0_18n7e_310",
  "dropdown-drop-right": "awsui_dropdown-drop-right_qwoo0_18n7e_316",
  "occupy-entire-width": "awsui_occupy-entire-width_qwoo0_18n7e_322",
  "stretch-beyond-trigger-width": "awsui_stretch-beyond-trigger-width_qwoo0_18n7e_325",
  "hide-block-border": "awsui_hide-block-border_qwoo0_18n7e_329",
  "open": "awsui_open_qwoo0_18n7e_335",
  "nowrap": "awsui_nowrap_qwoo0_18n7e_344",
  "dropdown-content": "awsui_dropdown-content_qwoo0_18n7e_151",
  "stretch-trigger-height": "awsui_stretch-trigger-height_qwoo0_18n7e_355"
};
  