import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBadgeGroup } from '../common/RadBadgeGroup'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function DataProductDetail () {
  const navigate = useNavigate()
  const { dataProductId } = useParams()
  const { data: dataProduct } = useGet(`/api/data-product/${dataProductId}`, true)
  const confirmDelete = useConfirm('Delete data product?', 'Delete data product permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/data-product/${dataProductId}`, () => { navigate('/data-product') })

  if (dataProduct != null) {
    return (
      <RadAppLayout
        name={dataProduct.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={dataProduct.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
            description={dataProduct.description}
          >
            {dataProduct.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadColumnLayout columns={4} borders='vertical'>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Administrator
                  </RadBox>
                  <RadLink href={`/person/${dataProduct.administrator.id}`}>{dataProduct.administrator.fullName}</RadLink>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Data Assets
                  </RadBox>
                  <div>{dataProduct.dataAssets.length > 0
                    ? dataProduct.dataAssets.map((x) => <div key={x.id}><RadLink href={`/data-asset/${x.dataAsset.id}`}>{x.dataAsset.name}</RadLink></div>)
                    : '-'}
                  </div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Data Producers
                  </RadBox>
                  <div>{dataProduct.dataProducers.length > 0
                    ? dataProduct.dataProducers.map((x) => <div key={x.id}><RadLink href={`/${x.type}/${x.id}`}>{x.name}</RadLink></div>)
                    : '-'}
                  </div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Data Stewards
                  </RadBox>
                  <div>{dataProduct.dataStewards.length > 0
                    ? dataProduct.dataStewards.map((x) => <div key={x.id}><RadLink href={`/${x.type}/${x.id}`}>{x.name}</RadLink></div>)
                    : '-'}
                  </div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Governance Indicators
                  </RadBox>
                  <RadBadgeGroup
                    parentKey={dataProductId}
                    items={dataProduct.governanceIndicators}
                    mapFunc={x => ({ key: x.id, name: x.type.name })}
                  />
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Tags
                  </RadBox>
                  <RadBadgeGroup
                    parentKey={dataProduct}
                    items={dataProduct.tags}
                    mapFunc={x => ({ key: x.id, name: x.tag.name })}
                  />
                </div>
              </RadColumnLayout>
            </RadContainer>
          </RadSpaceBetween>
        }
      />
    )
  }
}
