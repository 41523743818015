import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadAutosuggest } from '../common/RadAutosuggest'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadFileUpload } from '../common/RadFileUpload'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'
import { RadCheckbox } from '../common/RadCheckbox'
import { toBase64 } from '../common/utilities'

export function InsightEdit () {
  const navigate = useNavigate()
  const { insightId } = useParams()
  const [files, setFiles] = useState([])
  const [formValues, setFormValues] = useState()
  const [userFilterText, setUserFilterText] = useState('')
  const { data: collaborativeOptions } = useGet('/api/option/collaborative')
  const { data: dataProduct } = useGet(insightId ? `/api/insight/${insightId}` : null)
  const { data: dataProductOptions } = useGet('/api/option/data-product?required=true')
  const { data: organizationOptions } = useGet('/api/option/organization')
  const { data: typeOptions } = useGet('/api/option/type?entity=insight&required=true')
  const { data: tags } = useGet('/api/tag')
  const { data: userInfo } = useGet('/api/user/current')
  const { data: userOptions } = useGet(`/api/option/user?search=${userFilterText}`)
  const create = usePost('/api/insight', formValues, (resp) => { navigate(`/insight/${resp.id}`) })
  const update = usePut(`/api/insight/${insightId}`, formValues, (resp) => { navigate(`/insight/${insightId}`) })

  useEffect(() => {
    const defaultFormValues = { isPrivate: false, dataProducts: [], partners: [], shares: [], tags: [] }
    setFormValues(dataProduct ?? defaultFormValues)
    if (dataProduct?.reportFile != null) {
      const file = new File([], dataProduct.reportFile.path.split('/').pop(), {
        type: dataProduct.reportFile.type,
        lastModifiedDate: dataProduct.reportFile.updatedAt
      })
      setFiles([file])
    }
  }, [dataProduct])

  async function cancel () {
    if (insightId != null) {
      navigate(`/insight/${insightId}`)
    } else {
      navigate('/insight')
    }
  }

  async function saveChanges () {
    if (insightId != null) {
      update()
    } else {
      create()
    }
  }

  if (
    formValues != null &&
    collaborativeOptions != null &&
    dataProductOptions != null &&
    organizationOptions != null &&
    typeOptions != null &&
    tags != null &&
    userInfo != null
  ) {
    const combinedOptions = [
      ...collaborativeOptions.map(x => ({ ...x, type: 'collaborative' })),
      ...organizationOptions.map(x => ({ ...x, type: 'organization' }))
    ].sort((a, b) => a.label.localeCompare(b.label))
    const canEditPrivacy = insightId == null || userInfo.roles.find((x) => x.name === 'Admin') != null || userInfo.id === formValues.createdBy

    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>{insightId ? 'Edit' : 'New'} Insight</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Name *' field='name'>
                      <RadInput
                        ariaRequired
                        placeholder='Enter name'
                        value={formValues.name}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Access Type *' field='accessTypeId'>
                      <RadSelect
                        selectedOption={formValues.accessTypeId ? typeOptions.find(x => x.value === formValues.accessTypeId?.toString()) : null}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, accessTypeId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, accessTypeId: null })
                          }
                        }}
                        options={typeOptions}
                        selectedAriaLabel='Selected'
                        placeholder='Choose an access type'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Description *' field='description'>
                      <RadTextarea
                        placeholder='Enter description'
                        value={formValues.description}
                        onChange={({ detail }) => setFormValues({ ...formValues, description: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Location' field='location'>
                      <RadTextarea
                        placeholder='Enter location'
                        value={formValues.location}
                        onChange={({ detail }) => setFormValues({ ...formValues, location: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Report File' field='reportFileId'>
                      <RadFileUpload
                        onChange={async ({ detail }) => {
                          setFiles(detail.value)
                          if (detail.value[0] != null) {
                            const reportFile = {
                              name: detail.value[0].name,
                              type: detail.value[0].type
                            }
                            reportFile.data = await toBase64(detail.value[0])
                            setFormValues({ ...formValues, reportFile })
                          } else {
                            setFormValues({ ...formValues, reportFile: null })
                          }
                        }}
                        value={files}
                        accept='application/pdf'
                        i18nStrings={{
                          uploadButtonText: e => e ? 'Choose files' : 'Choose file',
                          dropzoneText: e => e ? 'Drop files to upload' : 'Drop file to upload',
                          removeFileAriaLabel: e => `Remove file ${e + 1}`,
                          limitShowFewer: 'Show fewer files',
                          limitShowMore: 'Show more files',
                          errorIconAriaLabel: 'Error'
                        }}
                        showFileThumbnail={false}
                        tokenLimit={1}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Data Products *</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const dataProducts = formValues.dataProducts
                      dataProducts.push({ uuid: uuidv4(), primaryEmployer: false })
                      setFormValues({ ...formValues, dataProducts })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const dataProducts = [...formValues.dataProducts]
                      dataProducts.splice(itemIndex, 1)
                      setFormValues({ ...formValues, dataProducts })
                    }}
                    items={formValues.dataProducts}
                    addButtonText='Add new data product'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const filteredOptions = dataProductOptions.filter((x) => !formValues.dataProducts.map((y) => y.dataProductId?.toString()).includes(x.value))
                          return (
                            <RadFormField field={`dataProduct.${item.id ?? item.uuid}.dataProductId`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={dataProductOptions.find(x => x.value === item.dataProductId?.toString())}
                                onChange={({ detail }) => {
                                  const dataProducts = formValues.dataProducts
                                  item.dataProductId = parseInt(detail.selectedOption.value)
                                  setFormValues({ ...formValues, dataProducts })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a data product'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No data products added to this insight.'
                  />
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Partners *</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const partners = formValues.partners
                      partners.push({ uuid: uuidv4() })
                      setFormValues({ ...formValues, partners })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const partners = [...formValues.partners]
                      partners.splice(itemIndex, 1)
                      setFormValues({ ...formValues, partners })
                    }}
                    items={formValues.partners}
                    addButtonText='Add new partner'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const filteredOptions = combinedOptions.filter((x) =>
                            !formValues.partners.map((y) => x.type === 'organization' && y.organizationId?.toString()).includes(x.value) &&
                            !formValues.partners.map((y) => x.type === 'collaborative' && y.collaborativeId?.toString()).includes(x.value)
                          )
                          return (
                            <RadFormField field={`partner.${item.id ?? item.uuid}.partnerId`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={combinedOptions.find(x =>
                                  (x.value === item.collaborativeId?.toString() && x.type === 'collaborative') ||
                                  (x.value === item.organizationId?.toString() && x.type === 'organization')
                                )}
                                onChange={({ detail }) => {
                                  const partners = formValues.partners
                                  switch (detail.selectedOption.type) {
                                    case 'collaborative':
                                      item.collaborativeId = parseInt(detail.selectedOption.value)
                                      item.organizationId = null
                                      break
                                    case 'organization':
                                      item.collaborativeId = null
                                      item.organizationId = parseInt(detail.selectedOption.value)
                                      break
                                    default:
                                      break
                                  }
                                  setFormValues({ ...formValues, partners })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a partner'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No partners added to this project.'
                  />
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Suggested Questions</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const suggestedQuestions = formValues.suggestedQuestions
                      suggestedQuestions.push({ uuid: uuidv4() })
                      setFormValues({ ...formValues, suggestedQuestions })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const suggestedQuestions = [...formValues.suggestedQuestions]
                      suggestedQuestions.splice(itemIndex, 1)
                      setFormValues({ ...formValues, suggestedQuestions })
                    }}
                    items={formValues.suggestedQuestions}
                    addButtonText='Add new suggested question'
                    definition={[
                      {
                        label: 'Question *',
                        control: item => {
                          return (
                            <RadFormField field={`suggestedQuestions.${item.id ?? item.uuid}.question`}>
                              <RadInput
                                onChange={({ detail }) => {
                                  const suggestedQuestions = formValues.suggestedQuestions
                                  item.question = detail.value
                                  setFormValues({ ...formValues, suggestedQuestions })
                                }}
                                value={item.question}
                                placeholder='Enter question'
                                ariaRequired
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No suggested questions added to this insight.'
                  />
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Tags</RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadAttributeEditor
                      onAddButtonClick={() => {
                        const tags = formValues.tags
                        tags.push({ uuid: uuidv4(), tag: {} })
                        setFormValues({ ...formValues, tags })
                      }}
                      onRemoveButtonClick={({
                        detail: { itemIndex }
                      }) => {
                        const tags = [...formValues.tags]
                        tags.splice(itemIndex, 1)
                        setFormValues({ ...formValues, tags })
                      }}
                      items={formValues.tags}
                      addButtonText='Add new tag'
                      definition={[
                        {
                          label: 'Name *',
                          control: item => {
                            const tagOptions = tags.map((x) => { return { id: x.id, value: x.name } })
                            const filteredOptions = (tagOptions ?? []).filter((x) => !formValues.tags.map((y) => y.tag.name).includes(x.value))
                            return (
                              <RadFormField field={`tag.${item.id || item.uuid}.name`}>
                                <RadAutosuggest
                                  options={filteredOptions}
                                  value={item.tag.name ?? ''}
                                  enteredTextLabel={value => `Use: "${value}"`}
                                  placeholder='Enter tag'
                                  empty='No matches found'
                                  onChange={({ detail }) => {
                                    const tags = formValues.tags
                                    const id = tagOptions.find((x) => x.value === detail.value)?.id
                                    item.id = null
                                    item.tag.id = id
                                    item.tag.name = detail.value
                                    setFormValues({ ...formValues, tags })
                                  }}
                                />
                              </RadFormField>
                            )
                          }
                        }
                      ]}
                      removeButtonText='Remove'
                      empty='No tags added to this insight.'
                    />
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader
                      variant='h2'
                      description={canEditPrivacy ? null : 'Only the creator of this resource or an administrator can make changes to its visibility settings.'}
                    >
                      Visibility
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadCheckbox
                      checked={formValues.isPrivate}
                      onChange={({ detail }) => {
                        setFormValues({ ...formValues, isPrivate: detail.checked })
                      }}
                      disabled={!canEditPrivacy}
                    >
                      Private
                    </RadCheckbox>
                    {formValues.isPrivate &&
                      <RadAttributeEditor
                        onAddButtonClick={() => {
                          const shares = formValues.shares
                          shares.push({ uuid: uuidv4(), allowEdit: false, user: {} })
                          setFormValues({ ...formValues, shares })
                        }}
                        onRemoveButtonClick={({
                          detail: { itemIndex }
                        }) => {
                          const shares = [...formValues.shares]
                          shares.splice(itemIndex, 1)
                          setFormValues({ ...formValues, shares })
                        }}
                        items={formValues.shares}
                        addButtonText='Add new user'
                        disableAddButton={!canEditPrivacy}
                        isItemRemovable={item => canEditPrivacy}
                        definition={[
                          {
                            label: 'User *',
                            control: item => {
                              const filteredOptions = (userOptions ?? []).filter((x) => !formValues.shares.map((y) => y.userId?.toString()).includes(x.value))
                              const selectedOption = item.userId ? { value: item.userId.toString(), label: item.user.name } : null
                              return (
                                <RadFormField field={`shares.${item.id || item.uuid}.userId`}>
                                  <RadSelect
                                    selectedOption={selectedOption}
                                    onChange={({ detail }) => {
                                      const shares = formValues.shares
                                      const user = formValues.shares.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                      user.userId = parseInt(detail.selectedOption.value)
                                      user.user.name = detail.selectedOption.label
                                      setFormValues({ ...formValues, shares })
                                    }}
                                    options={filteredOptions}
                                    enteredTextLabel={value => value}
                                    selectedAriaLabel='Selected'
                                    placeholder='Choose a user'
                                    filteringType='manual'
                                    onLoadItems={({ detail }) => {
                                      setUserFilterText(detail.filteringText)
                                    }}
                                    empty={userFilterText ? 'No matches found' : null}
                                    disabled={!canEditPrivacy}
                                  />
                                </RadFormField>
                              )
                            }
                          }
                          // {
                          //   label: 'Permission *',
                          //   control: item => {
                          //     const options = [
                          //       { value: false, label: 'View only' },
                          //       { value: true, label: 'View and edit' }
                          //     ]
                          //     return (
                          //       <RadFormField field={`shares.${item.id ?? item.uuid}.allowEdit`}>
                          //         <RadSelect
                          //           filteringType='auto'
                          //           selectedOption={options.find(x => x.value === item.allowEdit)}
                          //           onChange={({ detail }) => {
                          //             const shares = formValues.shares
                          //             const user = formValues.shares.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                          //             user.allowEdit = detail.selectedOption.value
                          //             setFormValues({ ...formValues, shares })
                          //           }}
                          //           options={options}
                          //           enteredTextLabel={value => value}
                          //           selectedAriaLabel='Selected'
                          //           placeholder='Choose a sector'
                          //           empty='No matches found'
                          //           disabled={!canEditPrivacy}
                          //         />
                          //       </RadFormField>
                          //     )
                          //   }
                          // }
                        ]}
                        removeButtonText='Remove'
                        empty='No users added to this insight.'
                      />}
                  </RadSpaceBetween>
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
