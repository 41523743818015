import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'

export function VendorEdit () {
  const navigate = useNavigate()
  const { vendorId } = useParams()
  const [formValues, setFormValues] = useState()
  const { data: vendor } = useGet(vendorId ? `/api/vendor/${vendorId}` : null)
  const create = usePost('/api/vendor', formValues, (resp) => { navigate(`/vendor/${resp.id}`) })
  const update = usePut(`/api/vendor/${vendorId}`, formValues, (resp) => { navigate(`/vendor/${vendorId}`) })

  useEffect(() => {
    const defaultFormValues = { products: [], governanceIndicators: [], insights: [] }
    setFormValues(vendor ?? defaultFormValues)
  }, [vendor])

  async function cancel () {
    if (vendorId != null) {
      navigate(`/vendor/${vendorId}`)
    } else {
      navigate('/vendor')
    }
  }

  async function saveChanges () {
    if (vendorId != null) {
      update()
    } else {
      create()
    }
  }

  if (
    formValues != null
  ) {
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>{vendorId ? 'Edit' : 'New'} Vendor</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Name *' field='name'>
                      <RadInput
                        ariaRequired
                        placeholder='Enter name'
                        value={formValues.name}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Description' field='description'>
                      <RadTextarea
                        placeholder='Enter description'
                        value={formValues.description}
                        onChange={({ detail }) => setFormValues({ ...formValues, description: detail.value })}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Products</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const products = formValues.products
                      products.push({ uuid: uuidv4() })
                      setFormValues({ ...formValues, products })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const products = [...formValues.products]
                      products.splice(itemIndex, 1)
                      setFormValues({ ...formValues, products })
                    }}
                    items={formValues.products}
                    addButtonText='Add new product'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          return (
                            <RadFormField field={`product.${item.id ?? item.uuid}.name`}>
                              <RadInput
                                ariaRequired
                                placeholder='Enter name'
                                value={item.name}
                                onChange={({ detail }) => {
                                  const products = formValues.products
                                  item.name = detail.value
                                  setFormValues({ ...formValues, products })
                                }}
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No data products added to this vendor.'
                  />
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
