import React, { useContext } from 'react'
import Form from '@cloudscape-design/components/form'
import { AppContext } from './../App'

// https://cloudscape.design/components/form
export function RadForm ({ ...rest }) {
  const { error } = useContext(AppContext)

  return (
    <Form
      errorText={error != null ? error?.code + (error?.message != null ? ': ' + error?.message : '') : null}
      {...rest}
    />
  )
}
