
    import './styles.scoped.css';
    export default {
  "content": "awsui_content_vjswe_drriy_151",
  "button": "awsui_button_vjswe_drriy_155",
  "variant-normal": "awsui_variant-normal_vjswe_drriy_206",
  "variant-icon": "awsui_variant-icon_vjswe_drriy_249",
  "variant-modal-dismiss": "awsui_variant-modal-dismiss_vjswe_drriy_249",
  "variant-flashbar-icon": "awsui_variant-flashbar-icon_vjswe_drriy_249",
  "variant-inline-icon": "awsui_variant-inline-icon_vjswe_drriy_270",
  "disabled": "awsui_disabled_vjswe_drriy_291",
  "variant-primary": "awsui_variant-primary_vjswe_drriy_299",
  "variant-link": "awsui_variant-link_vjswe_drriy_392",
  "variant-inline-link": "awsui_variant-inline-link_vjswe_drriy_671",
  "variant-breadcrumb-group": "awsui_variant-breadcrumb-group_vjswe_drriy_954",
  "variant-menu-trigger": "awsui_variant-menu-trigger_vjswe_drriy_1052",
  "button-no-text": "awsui_button-no-text_vjswe_drriy_1145",
  "button-no-wrap": "awsui_button-no-wrap_vjswe_drriy_1149",
  "full-width": "awsui_full-width_vjswe_drriy_1152",
  "icon-left": "awsui_icon-left_vjswe_drriy_1171",
  "icon-right": "awsui_icon-right_vjswe_drriy_1176",
  "icon": "awsui_icon_vjswe_drriy_1171"
};
  