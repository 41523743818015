import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGet } from './../hooks/useGet'
import { Avatar } from '@rmwc/avatar'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput2 } from '../common/RadInput2'
import { RadLink } from '../common/RadLink'
import { RadMultiselect } from '../common/RadMultiselect'
import { RadPagination } from '../common/RadPagination'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function PersonList () {
  const pageLength = 20
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const [tagIds, setTagIds] = useState(searchParams.get('tagIds') ?? '')
  const { data: people, count } = useGet(`/api/person?search=${searchText}&tagIds=${tagIds ?? ''}&limit=${pageLength}&offset=${(currentPageIndex - 1) * pageLength}`)
  const { data: tags } = useGet('/api/tag?limit=2000')

  if
  (people != null && tags != null) {
    const tagOptions = tags.map((x) => { return { value: x.id.toString(), label: x.name } })
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            counter={'(' + count + ')'}
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('/person/create')}>Create</RadButton>
              </RadSpaceBetween>
            }
          >
            People
          </RadHeader>
        }
        content={
          <RadCards
            ariaLabels={{
              itemSelectionLabel: (e, t) => `select ${t.name}`,
              selectionGroupLabel: 'Item selection'
            }}
            cardDefinition={{
              header: item => (
                <>
                  <Avatar
                    src={item.photoUrl}
                    size='large'
                    name={`${item.firstName} ${item.lastName}`}
                    referrerPolicy='no-referrer'
                  />
                  <br />
                  <RadLink fontSize='heading-m' href={`/person/${item.id}`}>{`${item.firstName} ${item.preferredName ? '"' + item.preferredName + '" ' : ''}${item.lastName}`}</RadLink>
                </>
              ),
              sections: [
                {
                  id: 'email',
                  content: item => <RadLink href={`mailto:${item.email}`}>{item.email}</RadLink>

                },
                {
                  id: 'organizations',
                  header: 'Organizations',
                  content: item => item.organizations.length > 0
                    ? item.organizations.map((x) => <div key={x.id}><RadLink href={`/organization/${x.id}`}>{x.name}</RadLink><br />{x.title} {x.primaryEmployer ? ' (Primary Employer)' : ''}</div>)
                    : '-'
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={people}
            filter={
              <RadGrid
                gridDefinition={[
                  { colspan: { default: 12, xs: 9 } },
                  { colspan: { default: 12, xs: 3 } }
                ]}
              >
                <RadInput2
                  initialValue={searchText}
                  placeholder='Search'
                  type='search'
                  onEnter={({ detail }) => {
                    setSearchText(detail.value)
                    if (detail.value) {
                      searchParams.set('search', detail.value)
                    } else {
                      searchParams.delete('search')
                    }
                    setSearchParams(searchParams)
                  }}
                />
                <RadMultiselect
                  filteringType='auto'
                  selectedOptions={tagOptions.filter((x) => tagIds.split('-').includes(x.value))}
                  onChange={({ detail }) => {
                    const ids = detail.selectedOptions.map((x) => x.value).join('-')
                    searchParams.set('tagIds', ids)
                    setTagIds(ids)
                    searchParams.set('page', 1)
                    setCurrentPageIndex(1)
                    setSearchParams(searchParams)
                  }}
                  options={tagOptions}
                  selectedAriaLabel='Selected'
                  deselectAriaLabel={e => `Remove ${e.label}`}
                  placeholder='Choose tags'
                  keepOpen={false}
                />
              </RadGrid>
            }
            pagination={
              <RadPagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                  setCurrentPageIndex(detail.currentPageIndex)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            }
            variant='full-page'
            empty={
              <RadBox textAlign='center' color='inherit'>
                No matches found.
              </RadBox>
            }
          />
        }
      />
    )
  }
}
