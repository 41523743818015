
    import './styles.scoped.css';
    export default {
  "background": "awsui_background_hyvsj_13tiq_147",
  "scrolling-background": "awsui_scrolling-background_hyvsj_13tiq_150",
  "sticky-background": "awsui_sticky-background_hyvsj_13tiq_156",
  "has-sticky-notifications": "awsui_has-sticky-notifications_hyvsj_13tiq_164",
  "breadcrumbs": "awsui_breadcrumbs_hyvsj_13tiq_175",
  "has-sticky-background": "awsui_has-sticky-background_hyvsj_13tiq_185",
  "drawers-container": "awsui_drawers-container_hyvsj_13tiq_197",
  "has-open-drawer": "awsui_has-open-drawer_hyvsj_13tiq_208",
  "disable-body-scroll": "awsui_disable-body-scroll_hyvsj_13tiq_231",
  "drawers-desktop-triggers-container": "awsui_drawers-desktop-triggers-container_hyvsj_13tiq_236",
  "has-multiple-triggers": "awsui_has-multiple-triggers_hyvsj_13tiq_277",
  "drawers-mobile-triggers-container": "awsui_drawers-mobile-triggers-container_hyvsj_13tiq_287",
  "drawers-trigger-content": "awsui_drawers-trigger-content_hyvsj_13tiq_291",
  "drawers-trigger-overflow": "awsui_drawers-trigger-overflow_hyvsj_13tiq_305",
  "drawers-trigger": "awsui_drawers-trigger_hyvsj_13tiq_291",
  "drawer": "awsui_drawer_hyvsj_13tiq_197",
  "drawer-content-container": "awsui_drawer-content-container_hyvsj_13tiq_336",
  "drawer-close-button": "awsui_drawer-close-button_hyvsj_13tiq_345",
  "drawer-content": "awsui_drawer-content_hyvsj_13tiq_336",
  "drawer-content-hidden": "awsui_drawer-content-hidden_hyvsj_13tiq_353",
  "drawer-slider": "awsui_drawer-slider_hyvsj_13tiq_356",
  "is-drawer-open": "awsui_is-drawer-open_hyvsj_13tiq_363",
  "content": "awsui_content_hyvsj_13tiq_389",
  "layout": "awsui_layout_hyvsj_13tiq_414",
  "has-max-content-width": "awsui_has-max-content-width_hyvsj_13tiq_497",
  "content-type-dashboard": "awsui_content-type-dashboard_hyvsj_13tiq_512",
  "content-type-table": "awsui_content-type-table_hyvsj_13tiq_526",
  "content-type-cards": "awsui_content-type-cards_hyvsj_13tiq_526",
  "is-overlap-disabled": "awsui_is-overlap-disabled_hyvsj_13tiq_532",
  "is-hide-mobile-toolbar": "awsui_is-hide-mobile-toolbar_hyvsj_13tiq_535",
  "has-content-gap-left": "awsui_has-content-gap-left_hyvsj_13tiq_561",
  "has-content-gap-right": "awsui_has-content-gap-right_hyvsj_13tiq_564",
  "content-first-child-notifications": "awsui_content-first-child-notifications_hyvsj_13tiq_574",
  "has-breadcrumbs": "awsui_has-breadcrumbs_hyvsj_13tiq_577",
  "content-first-child-header": "awsui_content-first-child-header_hyvsj_13tiq_580",
  "has-header": "awsui_has-header_hyvsj_13tiq_580",
  "content-first-child-main": "awsui_content-first-child-main_hyvsj_13tiq_601",
  "disable-content-paddings": "awsui_disable-content-paddings_hyvsj_13tiq_601",
  "has-split-panel": "awsui_has-split-panel_hyvsj_13tiq_644",
  "split-panel-position-bottom": "awsui_split-panel-position-bottom_hyvsj_13tiq_644",
  "block-body-scroll": "awsui_block-body-scroll_hyvsj_13tiq_658",
  "unfocusable": "awsui_unfocusable_hyvsj_13tiq_663",
  "container": "awsui_container_hyvsj_13tiq_673",
  "is-navigation-open": "awsui_is-navigation-open_hyvsj_13tiq_699",
  "is-tools-open": "awsui_is-tools-open_hyvsj_13tiq_702",
  "is-split-panel-open": "awsui_is-split-panel-open_hyvsj_13tiq_702",
  "split-panel-position-side": "awsui_split-panel-position-side_hyvsj_13tiq_702",
  "has-active-drawer": "awsui_has-active-drawer_hyvsj_13tiq_702",
  "mobile-toolbar": "awsui_mobile-toolbar_hyvsj_13tiq_711",
  "remove-high-contrast-header": "awsui_remove-high-contrast-header_hyvsj_13tiq_728",
  "mobile-toolbar-nav": "awsui_mobile-toolbar-nav_hyvsj_13tiq_732",
  "mobile-toolbar-breadcrumbs": "awsui_mobile-toolbar-breadcrumbs_hyvsj_13tiq_736",
  "mobile-toolbar-tools": "awsui_mobile-toolbar-tools_hyvsj_13tiq_740",
  "navigation-container": "awsui_navigation-container_hyvsj_13tiq_749",
  "show-navigation": "awsui_show-navigation_hyvsj_13tiq_787",
  "animating": "awsui_animating_hyvsj_13tiq_810",
  "showButtons": "awsui_showButtons_hyvsj_13tiq_1",
  "navigation": "awsui_navigation_hyvsj_13tiq_749",
  "openNavigation": "awsui_openNavigation_hyvsj_13tiq_1",
  "animated-content": "awsui_animated-content_hyvsj_13tiq_872",
  "hide-navigation": "awsui_hide-navigation_hyvsj_13tiq_881",
  "notifications": "awsui_notifications_hyvsj_13tiq_891",
  "sticky-notifications": "awsui_sticky-notifications_hyvsj_13tiq_897",
  "split-panel-bottom": "awsui_split-panel-bottom_hyvsj_13tiq_914",
  "position-bottom": "awsui_position-bottom_hyvsj_13tiq_961",
  "openSplitPanelBottom": "awsui_openSplitPanelBottom_hyvsj_13tiq_1",
  "split-panel-side": "awsui_split-panel-side_hyvsj_13tiq_990",
  "position-side": "awsui_position-side_hyvsj_13tiq_1003",
  "tools-container": "awsui_tools-container_hyvsj_13tiq_1025",
  "tools": "awsui_tools_hyvsj_13tiq_1025",
  "openTools": "awsui_openTools_hyvsj_13tiq_1",
  "has-tools-form-persistence": "awsui_has-tools-form-persistence_hyvsj_13tiq_1116",
  "hide-tools": "awsui_hide-tools_hyvsj_13tiq_1126",
  "show-tools": "awsui_show-tools_hyvsj_13tiq_1138",
  "has-tools-form": "awsui_has-tools-form_hyvsj_13tiq_1116",
  "trigger-badge-wrapper": "awsui_trigger-badge-wrapper_hyvsj_13tiq_1221",
  "trigger": "awsui_trigger_hyvsj_13tiq_1221",
  "selected": "awsui_selected_hyvsj_13tiq_1295",
  "badge": "awsui_badge_hyvsj_13tiq_1315",
  "trigger-wrapper": "awsui_trigger-wrapper_hyvsj_13tiq_1319",
  "dot": "awsui_dot_hyvsj_13tiq_1330"
};
  