import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function DataSourceDetail () {
  const navigate = useNavigate()
  const { dataSourceId } = useParams()
  const { data: dataSource } = useGet(`/api/data-source/${dataSourceId}`, true)
  const confirmDelete = useConfirm('Delete data source?', 'Delete data source permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/data-source/${dataSourceId}`, () => { navigate('/data-source') })

  if (dataSource != null) {
    return (
      <RadAppLayout
        name={dataSource.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={dataSource.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
            description={dataSource.description}
          >
            {dataSource.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadColumnLayout columns={4} borders='vertical'>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Source System
                  </RadBox>
                  <div>
                    {dataSource.sourceSystem != null ? <RadLink href={`/vendor/${dataSource.sourceSystem.vendor.id}`}>{dataSource.sourceSystem.vendor.name + ' ' + dataSource.sourceSystem.name}</RadLink> : '-'}
                  </div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Producer
                  </RadBox>
                  <div>
                    {dataSource.producerCollaborative != null ? <RadLink href={`/collaborative/${dataSource.producerCollaborative.id}`}>{dataSource.producerCollaborative.name}</RadLink> : ''}
                    {dataSource.producerOrganization != null ? <RadLink href={`/organization/${dataSource.producerOrganization.id}`}>{dataSource.producerOrganization.name}</RadLink> : ''}
                    {dataSource.producerCollaborative == null && dataSource.producerOrganization == null ? '-' : ''}
                  </div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Steward
                  </RadBox>
                  <div>
                    {dataSource.stewardCollaborative != null ? <RadLink href={`/collaborative/${dataSource.stewardCollaborative.id}`}>{dataSource.stewardCollaborative.name}</RadLink> : ''}
                    {dataSource.stewardOrganization != null ? <RadLink href={`/organization/${dataSource.stewardOrganization.id}`}>{dataSource.stewardOrganization.name}</RadLink> : ''}
                    {dataSource.stewardCollaborative == null && dataSource.stewardOrganization == null ? '-' : ''}
                  </div>
                </div>
              </RadColumnLayout>
            </RadContainer>
          </RadSpaceBetween>
        }
      />
    )
  }
}
