import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'

export function ProjectEdit () {
  const navigate = useNavigate()
  const { projectId } = useParams()
  const [formValues, setFormValues] = useState()
  const { data: project } = useGet(projectId ? `/api/project/${projectId}` : null)
  const { data: collaborativeOptions } = useGet('/api/option/collaborative')
  const { data: dataProductOptions } = useGet('/api/option/data-product?required=true')
  const { data: insightOptions } = useGet('/api/option/insight?required=true')
  const { data: organizationOptions } = useGet('/api/option/organization')
  const { data: personOptions } = useGet('/api/option/person')
  const { data: phaseOptions } = useGet('/api/option/type?entity=project_phase&required=true')
  const { data: statusOptions } = useGet('/api/option/type?entity=project_status&required=true')
  const create = usePost('/api/project', formValues, (resp) => { navigate(`/project/${resp.id}`) })
  const update = usePut(`/api/project/${projectId}`, formValues, (resp) => { navigate(`/project/${projectId}`) })

  useEffect(() => {
    const defaultFormValues = { dataProducts: [], insights: [], partners: [] }
    setFormValues(project ?? defaultFormValues)
  }, [project])

  async function cancel () {
    if (projectId != null) {
      navigate(`/project/${projectId}`)
    } else {
      navigate('/project')
    }
  }

  async function saveChanges () {
    if (projectId != null) {
      update()
    } else {
      create()
    }
  }

  if (
    formValues != null &&
    collaborativeOptions != null &&
    dataProductOptions != null &&
    organizationOptions != null &&
    personOptions != null &&
    phaseOptions != null &&
    statusOptions != null
  ) {
    const combinedOptions = [
      ...collaborativeOptions.map(x => ({ ...x, type: 'collaborative' })),
      ...organizationOptions.map(x => ({ ...x, type: 'organization' }))
    ].sort((a, b) => a.label.localeCompare(b.label))

    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>{projectId ? 'Edit' : 'New'} Project</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Name *' field='name'>
                      <RadInput
                        ariaRequired
                        placeholder='Enter name'
                        value={formValues.name}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Description *' field='description'>
                      <RadTextarea
                        placeholder='Enter description'
                        value={formValues.description}
                        onChange={({ detail }) => setFormValues({ ...formValues, description: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Status *' field='statusTypeId'>
                      <RadSelect
                        selectedOption={formValues.statusTypeId ? statusOptions.find(x => x.value === formValues.statusTypeId?.toString()) : null}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, statusTypeId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, statusTypeId: null })
                          }
                        }}
                        options={statusOptions}
                        selectedAriaLabel='Selected'
                        placeholder='Choose a status'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Phase *' field='phaseTypeId'>
                      <RadSelect
                        selectedOption={formValues.phaseTypeId ? phaseOptions.find(x => x.value === formValues.phaseTypeId?.toString()) : null}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, phaseTypeId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, phaseTypeId: null })
                          }
                        }}
                        options={phaseOptions}
                        selectedAriaLabel='Selected'
                        placeholder='Choose a phase'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Sponsor *' field='sponsorId' constraintText=''>
                      <RadSelect
                        filteringType='auto'
                        selectedOption={personOptions.find(x => x.value === formValues.sponsorId?.toString())}
                        onChange={({ detail }) => {
                          setFormValues({ ...formValues, sponsorId: parseInt(detail.selectedOption.value) })
                        }}
                        options={personOptions}
                        enteredTextLabel={value => value}
                        selectedAriaLabel='Selected'
                        placeholder='Choose a sponsor'
                        empty='No matches found'
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Data Products</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const dataProducts = formValues.dataProducts
                      dataProducts.push({ uuid: uuidv4() })
                      setFormValues({ ...formValues, dataProducts })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const dataProducts = [...formValues.dataProducts]
                      dataProducts.splice(itemIndex, 1)
                      setFormValues({ ...formValues, dataProducts })
                    }}
                    items={formValues.dataProducts}
                    addButtonText='Add new data product'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const filteredOptions = dataProductOptions.filter((x) => !formValues.dataProducts.map((y) => y.dataProductId?.toString()).includes(x.value))
                          return (
                            <RadFormField field={`dataProduct.${item.id ?? item.uuid}.dataProductId`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={dataProductOptions.find(x => x.value === item.dataProductId?.toString())}
                                onChange={({ detail }) => {
                                  const dataProducts = formValues.dataProducts
                                  item.dataProductId = parseInt(detail.selectedOption.value)
                                  setFormValues({ ...formValues, dataProducts })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a data product'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No data products added to this project.'
                  />
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Partners *</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const partners = formValues.partners
                      partners.push({ uuid: uuidv4() })
                      setFormValues({ ...formValues, partners })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const partners = [...formValues.partners]
                      partners.splice(itemIndex, 1)
                      setFormValues({ ...formValues, partners })
                    }}
                    items={formValues.partners}
                    addButtonText='Add new partner'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const filteredOptions = combinedOptions.filter((x) =>
                            !formValues.partners.map((y) => x.type === 'organization' && y.organizationId?.toString()).includes(x.value) &&
                            !formValues.partners.map((y) => x.type === 'collaborative' && y.collaborativeId?.toString()).includes(x.value)
                          )
                          return (
                            <RadFormField field={`partner.${item.id ?? item.uuid}.partnerId`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={combinedOptions.find(x =>
                                  (x.value === item.collaborativeId?.toString() && x.type === 'collaborative') ||
                                  (x.value === item.organizationId?.toString() && x.type === 'organization')
                                )}
                                onChange={({ detail }) => {
                                  const partners = formValues.partners
                                  switch (detail.selectedOption.type) {
                                    case 'collaborative':
                                      item.collaborativeId = parseInt(detail.selectedOption.value)
                                      item.organizationId = null
                                      break
                                    case 'organization':
                                      item.collaborativeId = null
                                      item.organizationId = parseInt(detail.selectedOption.value)
                                      break
                                    default:
                                      break
                                  }
                                  setFormValues({ ...formValues, partners })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a partner'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No partners added to this project.'
                  />
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Insights</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const insights = formValues.insights
                      insights.push({ uuid: uuidv4() })
                      setFormValues({ ...formValues, insights })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const insights = [...formValues.insights]
                      insights.splice(itemIndex, 1)
                      setFormValues({ ...formValues, insights })
                    }}
                    items={formValues.insights}
                    addButtonText='Add new insight'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const filteredOptions = insightOptions.filter((x) => !formValues.insights.map((y) => y.insightId?.toString()).includes(x.value))
                          return (
                            <RadFormField field={`insight.${item.id ?? item.uuid}.insightId`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={insightOptions.find(x => x.value === item.insightId?.toString())}
                                onChange={({ detail }) => {
                                  const insights = formValues.insights
                                  item.insightId = parseInt(detail.selectedOption.value)
                                  setFormValues({ ...formValues, insights })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose an insight'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No insights added to this project.'
                  />
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
