import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBadgeGroup } from '../common/RadBadgeGroup'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { formatDate, getDownloadUrl } from '../common/utilities'

export function DataAssetDetail () {
  const navigate = useNavigate()
  const { dataAssetId } = useParams()
  const { data: dataAsset } = useGet(`/api/data-asset/${dataAssetId}`, true)
  const confirmDelete = useConfirm('Delete data asset?', 'Delete data asset permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/data-asset/${dataAssetId}`, () => { navigate('/data-asset') })

  if (dataAsset != null) {
    return (
      <RadAppLayout
        name={dataAsset.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={dataAsset.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
            description={dataAsset.description}
          >
            {dataAsset.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadColumnLayout columns={4} borders='vertical'>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Location
                  </RadBox>
                  {dataAsset.location ?? '-'}
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Status
                  </RadBox>
                  {dataAsset.status?.name ?? '-'}
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Format
                  </RadBox>
                  {dataAsset.format?.name ?? '-'}
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Date Created
                  </RadBox>
                  <div>{formatDate(dataAsset.createdDate)}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    YAML File
                  </RadBox>
                  {dataAsset.yamlFile != null ? <RadLink href={getDownloadUrl('data-asset', dataAsset.id, dataAsset.yamlFile.path)} target='_blank'>{dataAsset.yamlFile.name}</RadLink> : '-'}
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Data Sources
                  </RadBox>
                  <div>{dataAsset.dataSources.length > 0
                    ? dataAsset.dataSources.map((x) => <div key={x.id}><RadLink href={`/data-source/${x.dataSource.id}`}>{x.dataSource.name}</RadLink></div>)
                    : '-'}
                  </div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Data Consumers
                  </RadBox>
                  <div>{dataAsset.dataConsumers.length > 0
                    ? dataAsset.dataConsumers.map((x) => <div key={x.id}><RadLink href={`/person/${x.person.id}`}>{x.person.fullName}</RadLink></div>)
                    : '-'}
                  </div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Governance Indicators
                  </RadBox>
                  <div>
                    <RadBadgeGroup
                      parentKey={dataAssetId}
                      items={dataAsset.governanceIndicators}
                      mapFunc={x => ({ key: x.id, name: x.type.name })}
                    />
                  </div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Data Producers
                  </RadBox>
                  <div>{dataAsset.dataProducers.length > 0
                    ? dataAsset.dataProducers.map((x) => <div key={x.id}><RadLink href={`/${x.type}/${x.id}`}>{x.name}</RadLink></div>)
                    : '-'}
                  </div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Data Stewards
                  </RadBox>
                  <div>{dataAsset.dataStewards.length > 0
                    ? dataAsset.dataStewards.map((x) => <div key={x.id}><RadLink href={`/${x.type}/${x.id}`}>{x.name}</RadLink></div>)
                    : '-'}
                  </div>
                </div>
              </RadColumnLayout>
            </RadContainer>
          </RadSpaceBetween>
        }
      />
    )
  }
}
