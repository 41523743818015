import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toTitleCase } from '../common/utilities'
import { v4 as uuidv4 } from 'uuid'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function TypeEdit () {
  const navigate = useNavigate()
  const { entity } = useParams()
  const { data: types } = useGet(entity ? `/api/type?entity=${entity}` : null)
  const [formValues, setFormValues] = useState(types)
  const update = usePost('/api/type', formValues, (resp) => { navigate('/type') })

  useEffect(() => {
    setFormValues(types != null
      ? { entity, types }
      : { entity, types: [] })
  }, [types, entity])

  async function cancel () {
    navigate('/type')
  }

  async function saveChanges () {
    update()
  }

  if (types != null) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader variant='h1'>Edit Type</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadContainer
                header={
                  <RadHeader variant='h2'>
                    {toTitleCase(entity)} Types
                  </RadHeader>
                }
              >
                <RadSpaceBetween size='l'>
                  {formValues.types.sort((a, b) => a.sortOrder - b.sortOrder).map((x) =>
                    <TypeEditor
                      key={`type-${x.id},${x.uuid}`}
                      item={x}
                      formValues={formValues}
                      setFormValues={setFormValues}
                      types={formValues.types}
                    />)}
                  <RadButton
                    onClick={() => {
                      const temp = formValues.types
                      temp.push({ uuid: uuidv4() })
                      setFormValues({ ...formValues, types: temp })
                    }}
                  >
                    Add new type
                  </RadButton>
                </RadSpaceBetween>
              </RadContainer>
            </RadForm>
          </form>
        }
      />
    )
  }
}

function TypeEditor ({ item, formValues, setFormValues, types }) {
  return (
    <RadSpaceBetween size='l'>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, xxs: 5 } },
          { colspan: { default: 12, xxs: 5 } }
        ]}
      >
        <RadFormField label={item === types[0] ? 'Name *' : null} field={`type.${item.id || item.uuid}.name`}>
          <RadInput
            value={item.name}
            placeholder='Enter name'
            onChange={({ detail }) => {
              item.name = detail.value
              setFormValues({ ...formValues, types })
            }}
          />
        </RadFormField>
        <RadBox padding={{ top: item === types[0] ? 'xl' : null }}>
          <RadSpaceBetween direction='horizontal' size='l'>
            <RadButton
              wrapText={false}
              disabled={item === types[0]}
              onClick={() => {
                const temp = types.map((x, i) => {
                  if (x.sortOrder === 0) { x.sortOrder = i + 1 }
                  return x
                })
                const index = temp.indexOf(item)
                temp[index].sortOrder -= 1
                if (index > 0) temp[index - 1].sortOrder += 1
                setFormValues({ ...formValues, types: temp })
              }}
            >
              Move up
            </RadButton>
            <RadButton
              wrapText={false}
              disabled={item === types[types.length - 1]}
              onClick={() => {
                const temp = types.map((x, i) => {
                  if (x.sortOrder === 0) { x.sortOrder = i + 1 }
                  return x
                })
                const index = temp.indexOf(item)
                temp[index].sortOrder += 1
                temp[index + 1].sortOrder -= 1
                setFormValues({ ...formValues, types: temp })
              }}
            >
              Move down
            </RadButton>
            <RadButton
              wrapText={false}
              onClick={() => {
                const temp = types.filter((x) => x !== item)
                setFormValues({ ...formValues, types: temp })
              }}
            >
              Remove type
            </RadButton>
          </RadSpaceBetween>
        </RadBox>
      </RadGrid>
      {/* <RadDivider /> */}
    </RadSpaceBetween>
  )
}
