import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useGet } from '../hooks/useGet'
import { usePost } from './../hooks/usePost'
import { usePut } from './../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadAutosuggest } from '../common/RadAutosuggest'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadSelect } from '../common/RadSelect'
import { RadCheckbox } from '../common/RadCheckbox'

export function PersonEdit () {
  const navigate = useNavigate()
  const { personId } = useParams()
  const [formValues, setFormValues] = useState()
  const { data: person } = useGet(personId ? `/api/person/${personId}` : null)
  const { data: organizationOptions } = useGet('/api/option/organization')
  const { data: titleOptions } = useGet('/api/option/type?entity=title')
  const { data: userInfo } = useGet('/api/user/current')
  const { data: tags } = useGet('/api/tag')
  const create = usePost('/api/person', formValues, (resp) => { navigate(`/person/${resp.id}`) })
  const update = usePut(`/api/person/${personId}`, formValues, (resp) => { navigate(`/person/${personId}`) })

  useEffect(() => {
    const defaultFormValues = { isPrivate: false, organizations: [], shares: [], tags: [] }
    setFormValues(person ?? defaultFormValues)
  }, [person])

  async function cancel () {
    if (personId != null) {
      navigate(`/person/${personId}`)
    } else {
      navigate('/person')
    }
  }

  async function saveChanges () {
    if (personId != null) {
      update()
    } else {
      create()
    }
  }

  if (
    formValues != null &&
    organizationOptions != null &&
    titleOptions != null &&
    tags != null &&
    userInfo != null
  ) {
    return (
      <RadAppLayout
        name={formValues.fullName}
        contentHeader={
          <RadHeader variant='h1'>{personId ? 'Edit' : 'New'} Person</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='First Name *' field='firstName'>
                      <RadInput
                        ariaRequired
                        placeholder='Enter first name'
                        value={formValues.firstName}
                        onChange={({ detail }) => setFormValues({ ...formValues, firstName: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Middle Name' field='middleName'>
                      <RadInput
                        ariaRequired
                        placeholder='Enter middle name'
                        value={formValues.middleName}
                        onChange={({ detail }) => setFormValues({ ...formValues, middleName: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Last Name *' field='lastName'>
                      <RadInput
                        ariaRequired
                        placeholder='Enter last name'
                        value={formValues.lastName}
                        onChange={({ detail }) => setFormValues({ ...formValues, lastName: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Preferred Name' field='preferredName'>
                      <RadInput
                        placeholder='Enter preferred name'
                        value={formValues.preferredName}
                        onChange={({ detail }) => setFormValues({ ...formValues, preferredName: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Email' field='email'>
                      <RadInput
                        type='email'
                        inputMode='email'
                        placeholder='Enter email'
                        value={formValues.email}
                        onChange={({ detail }) => setFormValues({ ...formValues, email: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Phone' field='phone'>
                      <RadInput
                        type='tel'
                        inputMode='tel'
                        placeholder='Enter phone'
                        value={formValues.phone}
                        onChange={({ detail }) => setFormValues({ ...formValues, phone: detail.value })}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>

                <RadContainer
                  header={
                    <RadHeader variant='h2'>Organizations</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const organizations = formValues.organizations
                      organizations.push({ uuid: uuidv4(), primaryEmployer: false })
                      setFormValues({ ...formValues, organizations })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const organizations = [...formValues.organizations]
                      organizations.splice(itemIndex, 1)
                      setFormValues({ ...formValues, organizations })
                    }}
                    items={formValues.organizations}
                    addButtonText='Add new organization'
                    definition={[
                      {
                        label: 'Organization *',
                        control: item => {
                          const organization = formValues.organizations.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                          return (
                            <RadFormField field={`organizations.${item.id ?? item.uuid}.organizationId`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={organizationOptions.find(x => x.value === item.organizationId?.toString())}
                                onChange={({ detail }) => {
                                  const organizations = formValues.organizations
                                  organization.organizationId = parseInt(detail.selectedOption.value)
                                  setFormValues({ ...formValues, organizations })
                                }}
                                options={organizationOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a organization'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      },
                      {
                        label: 'Title',
                        control: item => {
                          const organization = formValues.organizations.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                          return (
                            <RadFormField field={`organizations.${item.id ?? item.uuid}.title`}>
                              <RadInput
                                placeholder='Enter title'
                                ariaRequired
                                value={item.title}
                                onChange={({ detail }) => {
                                  const organizations = formValues.organizations
                                  organization.title = detail.value
                                  setFormValues({ ...formValues, organizations })
                                }}
                              />
                            </RadFormField>
                          )
                        }
                      },
                      {
                        label: 'Primary Employer',
                        control: item => {
                          const organization = formValues.organizations.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                          return (
                            <RadFormField field={`organizations.${item.id ?? item.uuid}.primaryEmployer`}>
                              <RadCheckbox
                                checked={organization.primaryEmployer}
                                onChange={({ detail }) => {
                                  const organizations = formValues.organizations
                                  organization.primaryEmployer = detail.checked
                                  setFormValues({ ...formValues, organizations })
                                }}
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No organizations added to this person.'
                  />
                </RadContainer>

                <RadContainer
                  header={
                    <RadHeader variant='h2'>Tags</RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadAttributeEditor
                      onAddButtonClick={() => {
                        const tags = formValues.tags
                        tags.push({ uuid: uuidv4(), tag: {} })
                        setFormValues({ ...formValues, tags })
                      }}
                      onRemoveButtonClick={({
                        detail: { itemIndex }
                      }) => {
                        const tags = [...formValues.tags]
                        tags.splice(itemIndex, 1)
                        setFormValues({ ...formValues, tags })
                      }}
                      items={formValues.tags}
                      addButtonText='Add new tag'
                      definition={[
                        {
                          label: 'Name *',
                          control: item => {
                            const tagOptions = tags.map((x) => { return { id: x.id, value: x.name } })
                            const filteredOptions = (tagOptions ?? []).filter((x) => !formValues.tags.map((y) => y.tag.name).includes(x.value))
                            return (
                              <RadFormField field={`tag.${item.id || item.uuid}.name`}>
                                <RadAutosuggest
                                  options={filteredOptions}
                                  value={item.tag.name ?? ''}
                                  enteredTextLabel={value => `Use: "${value}"`}
                                  placeholder='Enter tag'
                                  empty='No matches found'
                                  onChange={({ detail }) => {
                                    const tags = formValues.tags
                                    const id = tagOptions.find((x) => x.value === detail.value)?.id
                                    item.id = null
                                    item.tag.id = id
                                    item.tag.name = detail.value
                                    setFormValues({ ...formValues, tags })
                                  }}
                                />
                              </RadFormField>
                            )
                          }
                        }
                      ]}
                      removeButtonText='Remove'
                      empty='No tags added to this person.'
                    />
                  </RadSpaceBetween>
                </RadContainer>

              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
