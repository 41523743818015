import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadForm } from '../common/RadForm'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadTextarea } from '../common/RadTextarea'
import { RadFormField } from '../common/RadFormField'
import { RadButton } from '../common/RadButton'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { stateList } from '../common/stateList'
import { RadAutosuggest } from '../common/RadAutosuggest'

export function CollaborativeEdit () {
  const navigate = useNavigate()
  const { collaborativeId } = useParams()
  const [formValues, setFormValues] = useState()
  // const [targetAreaFilteringText, setTargetAreaFilteringText] = useState('')
  const { data: collaborative } = useGet(collaborativeId ? `/api/collaborative/${collaborativeId}` : null)
  const { data: organizationOptions } = useGet('/api/option/organization')
  // const { data: projectOptions } = useGet('/api/option/project')
  const { data: personOptions } = useGet('/api/option/person')
  const { data: sectorOptions } = useGet('/api/option/sector')
  // const { data: software } = useGet('/api/software')
  const { data: stageOptions } = useGet('/api/option/type?entity=collaborative_stage')
  // const { data: targetAreaOptions } = useGet(`/api/option/targetArea?search=${targetAreaFilteringText}`)
  const { data: titleOptions } = useGet('/api/option/type?entity=title')
  const { data: typeOptions } = useGet('/api/option/type?entity=organization')
  const { data: tags } = useGet('/api/tag')

  const stateListOptions = [...stateList]
  stateListOptions.unshift({ value: null, label: '-' })

  useEffect(() => {
    const defaultFormValues = {
      // contacts: [],
      columns: [],
      organizations: [],
      people: [],
      // projects: [],
      sectors: [],
      users: [],
      // software: [],
      tags: []
      // targetAreas: []
    }
    setFormValues(collaborative ?? defaultFormValues)
  }, [collaborative])

  const create = usePost('/api/collaborative', formValues, (resp) => { navigate(`/collaborative/${resp.id}`) })
  const update = usePut(`/api/collaborative/${collaborativeId}`, formValues, (resp) => { navigate(`/collaborative/${collaborativeId}`) })

  async function saveChanges () {
    if (collaborativeId != null) { update() } else { create() }
  }

  async function cancel () {
    if (collaborativeId != null) {
      navigate(`/collaborative/${collaborativeId}`)
    } else {
      navigate('/collaborative')
    }
  }

  if (
    formValues != null &&
    typeOptions != null &&
    organizationOptions != null &&
    personOptions != null &&
    // projectOptions != null &&
    titleOptions != null &&
    sectorOptions != null &&
    // software != null &&
    stageOptions != null &&
    tags != null
  ) {
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>
            {formValues.id != null ? 'Edit Collaborative' : 'New Collaborative'}
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>

                {/* Details */}
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Name *' field='name'>
                      <RadInput
                        placeholder='Enter name'
                        ariaRequired
                        value={formValues.name}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                        disabled={formValues.isProtected}
                      />
                    </RadFormField>
                    <RadFormField label='Description' field='description'>
                      <RadTextarea
                        placeholder='Enter description'
                        value={formValues.description}
                        onChange={({ detail }) => setFormValues({ ...formValues, description: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Established' field='established'>
                      <RadInput
                        type='date'
                        onChange={({ detail }) => setFormValues({ ...formValues, established: detail.value })}
                        value={formValues.established ?? ''}
                      />
                    </RadFormField>
                    <RadFormField label='Stage' field='stageId'>
                      <RadSelect
                        filteringType='auto'
                        selectedOption={stageOptions.find(x => x.value === formValues.stageId?.toString())}
                        onChange={({ detail }) => {
                          setFormValues({ ...formValues, stageId: parseInt(detail.selectedOption.value) })
                        }}
                        options={stageOptions}
                        enteredTextLabel={value => value}
                        selectedAriaLabel='Selected'
                        placeholder='Choose a stage'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Goal' field='goal'>
                      <RadTextarea
                        placeholder='Enter goal'
                        value={formValues.goal}
                        onChange={({ detail }) => setFormValues({ ...formValues, goal: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Metrics' field='metrics'>
                      <RadTextarea
                        placeholder='Enter Metrics'
                        value={formValues.metrics}
                        onChange={({ detail }) => setFormValues({ ...formValues, metrics: detail.value })}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>

                {/* Contacts */}
                <RadContainer
                  header={
                    <RadHeader variant='h2'>People</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const people = formValues.people
                      people.push({ uuid: uuidv4(), primaryEmployer: false })
                      setFormValues({ ...formValues, people })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const people = [...formValues.people]
                      people.splice(itemIndex, 1)
                      setFormValues({ ...formValues, people })
                    }}
                    items={formValues.people}
                    addButtonText='Add new person'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const filteredOptions = personOptions.filter((x) => !formValues.people.map((y) => y.personId?.toString()).includes(x.value))
                          return (
                            <RadFormField field={`people.${item.id ?? item.uuid}.personId`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={personOptions.find(x => x.value === item.personId?.toString())}
                                onChange={({ detail }) => {
                                  const people = formValues.people
                                  item.personId = parseInt(detail.selectedOption.value)
                                  setFormValues({ ...formValues, people })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a person'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No people added to this collaborative.'
                  />
                </RadContainer>

                {/* Organizations */}
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Organizations</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const organizations = formValues.organizations
                      organizations.push({ uuid: uuidv4() })
                      setFormValues({ ...formValues, organizations })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const organizations = [...formValues.organizations]
                      organizations.splice(itemIndex, 1)
                      setFormValues({ ...formValues, organizations })
                    }}
                    items={formValues.organizations}
                    addButtonText='Add new organization'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const filteredOptions = organizationOptions.filter((x) => !formValues.organizations.map((y) => y.organizationId?.toString()).includes(x.value))
                          const organization = formValues.organizations.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                          return (
                            <RadFormField field={`organizations.${item.id ?? item.uuid}.name`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={organizationOptions.find(x => x.value === item.organizationId?.toString())}
                                onChange={({ detail }) => {
                                  const organizations = formValues.organizations
                                  organization.organizationId = parseInt(detail.selectedOption.value)
                                  setFormValues({ ...formValues, organizations })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose an organization'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No organizations added to this collaborative.'
                  />
                </RadContainer>

                {/* Projects */}
                {/* <RadContainer
                  header={
                    <RadHeader variant='h2'>Projects</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const projects = formValues.projects
                      projects.push({ uuid: uuidv4() })
                      setFormValues({ ...formValues, projects })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const projects = [...formValues.projects]
                      projects.splice(itemIndex, 1)
                      setFormValues({ ...formValues, projects })
                    }}
                    items={formValues.projects}
                    addButtonText='Add new project'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const filteredOptions = projectOptions.filter((x) => !formValues.projects.map((y) => y.projectId?.toString()).includes(x.value))
                          const project = formValues.projects.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                          return (
                            <RadFormField field={`projects.${item.id ?? item.uuid}.name`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={projectOptions.find(x => x.value === item.projectId?.toString())}
                                onChange={({ detail }) => {
                                  const projects = formValues.projects
                                  project.projectId = parseInt(detail.selectedOption.value)
                                  setFormValues({ ...formValues, projects })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose an project'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No projects added to this collaborative.'
                  />
                </RadContainer> */}

                {/* Sectors */}
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Sectors</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const sectors = formValues.sectors
                      sectors.push({ uuid: uuidv4() })
                      setFormValues({ ...formValues, sectors })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const sectors = [...formValues.sectors]
                      sectors.splice(itemIndex, 1)
                      setFormValues({ ...formValues, sectors })
                    }}
                    items={formValues.sectors}
                    addButtonText='Add new sector'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const sectors = formValues.sectors
                          const sector = formValues.sectors.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                          const filteredOptions = sectorOptions.filter((x) => !formValues.sectors.map((y) => y.sectorId?.toString()).includes(x.value))
                          return (
                            <RadFormField field={`sectors.${item.id ?? item.uuid}.name`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={sectorOptions.find(x => x.value === item.sectorId?.toString())}
                                onChange={({ detail }) => {
                                  sector.sectorId = parseInt(detail.selectedOption.value)
                                  sector.name = detail.selectedOption.label
                                  sector.lastName = detail.selectedOption.label.split(' ')[1]
                                  setFormValues({ ...formValues, sectors })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a sector'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No sectors added to this collaborative.'
                  />
                </RadContainer>

                {/* Software */}
                {/* <RadContainer
                  header={
                    <RadHeader variant='h2'>Software</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const software = formValues.software
                      software.push({ uuid: uuidv4(), software: {} })
                      setFormValues({ ...formValues, software })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const software = [...formValues.software]
                      software.splice(itemIndex, 1)
                      setFormValues({ ...formValues, software })
                    }}
                    items={formValues.software}
                    addButtonText='Add new software'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const softwareOptions = software.map((x) => { return { value: x.id.toString(), label: x.name } })
                          const filteredOptions = (softwareOptions ?? []).filter((x) => !formValues.software.map((y) => y.name).includes(x.value))
                          return (
                            <RadFormField field={`software.${item.id ?? item.uuid}.name`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={softwareOptions.find(x => x.value === item.softwareId?.toString())}
                                onChange={({ detail }) => {
                                  const software = formValues.software
                                  const thisSoftware = formValues.software.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                  thisSoftware.id = null
                                  thisSoftware.softwareId = parseInt(detail.selectedOption.value)
                                  thisSoftware.software.name = detail.selectedOption.label
                                  setFormValues({ ...formValues, software })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a software'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      }

                    ]}
                    removeButtonText='Remove'
                    empty='No software added to this collaborative.'
                  />
                </RadContainer> */}

                {/* Tags */}
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Tags</RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadAttributeEditor
                      onAddButtonClick={() => {
                        const tags = formValues.tags
                        tags.push({ uuid: uuidv4(), tag: {} })
                        setFormValues({ ...formValues, tags })
                      }}
                      onRemoveButtonClick={({
                        detail: { itemIndex }
                      }) => {
                        const tags = [...formValues.tags]
                        tags.splice(itemIndex, 1)
                        setFormValues({ ...formValues, tags })
                      }}
                      items={formValues.tags}
                      addButtonText='Add new tag'
                      definition={[
                        {
                          label: 'Name *',
                          control: item => {
                            const tagOptions = tags.map((x) => { return { id: x.id, value: x.name } })
                            const filteredOptions = (tagOptions ?? []).filter((x) => !formValues.tags.map((y) => y.tag.name).includes(x.value))
                            return (
                              <RadFormField field={`tag.${item.id || item.uuid}.name`}>
                                <RadAutosuggest
                                  options={filteredOptions}
                                  value={item.tag.name ?? ''}
                                  enteredTextLabel={value => `Use: "${value}"`}
                                  placeholder='Enter tag'
                                  empty='No matches found'
                                  onChange={({ detail }) => {
                                    const tags = formValues.tags
                                    const id = tagOptions.find((x) => x.value === detail.value)?.id
                                    item.id = null
                                    item.tag.id = id
                                    item.tag.name = detail.value
                                    setFormValues({ ...formValues, tags })
                                  }}
                                />
                              </RadFormField>
                            )
                          }
                        }
                      ]}
                      removeButtonText='Remove'
                      empty='No tags added to this collaborative.'
                    />
                  </RadSpaceBetween>
                </RadContainer>

                {/* <RadContainer
                  header={
                    <RadHeader
                      variant='h2'
                      description='Target areas are states, counties, cities or zip codes.'
                    >
                      Target Areas
                    </RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const targetAreas = formValues.targetAreas
                      targetAreas.push({ uuid: uuidv4(), targetArea: {} })
                      setFormValues({ ...formValues, targetAreas })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const targetAreas = [...formValues.targetAreas]
                      targetAreas.splice(itemIndex, 1)
                      setFormValues({ ...formValues, targetAreas })
                    }}
                    items={formValues.targetAreas}
                    addButtonText='Add new target area'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const targetAreas = formValues.targetAreas
                          const targetArea = formValues.targetAreas.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                          const filteredOptions = (targetAreaOptions ?? []).filter((x) => formValues.targetAreas.find((y) => y.targetArea.name === x.label) == null)
                          return (
                            <RadFormField field={`targetAreas.${item.id ?? item.uuid}.name`}>
                              <RadSelect
                                selectedOption={item.targetAreaId != null ? { value: item.targetAreaId, label: item.targetArea.name } : null}
                                onChange={({ detail }) => {
                                  targetArea.targetAreaId = parseInt(detail.selectedOption.value)
                                  targetArea.targetArea.name = detail.selectedOption.label
                                  setFormValues({ ...formValues, targetAreas })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a target area'
                                filteringType='manual'
                                onLoadItems={({ detail }) => {
                                  setTargetAreaFilteringText(detail.filteringText)
                                }}
                                empty={targetAreaFilteringText !== '' ? 'No matches found' : null}
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No target areas added to this collaborative.'
                  />
                </RadContainer> */}
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
