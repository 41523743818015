import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadBox } from '../common/RadBox'
import { RadLink } from '../common/RadLink'
import { RadBadgeGroup } from '../common/RadBadgeGroup'

export function OrganizationDetail () {
  const navigate = useNavigate()
  const { organizationId } = useParams()
  const { data: organization } = useGet(`/api/organization/${organizationId}`, true)
  const confirmDelete = useConfirm('Delete organization?', 'Delete organization permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/organization/${organizationId}`, () => { navigate('/organization') })

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (organization != null) {
    return (
      <RadAppLayout
        name={organization.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={edit}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={organization.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
            description={organization.description}
          >
            {organization.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadColumnLayout columns={4} borders='vertical'>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Type
                  </RadBox>
                  <div>{organization.type?.name ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Address
                  </RadBox>
                  <div>{organization.address?.line1}</div>
                  {organization.address?.line2 && <div>{organization.address?.line2}</div>}
                  <div>{organization.address?.city} {organization.address?.state} {organization.address?.zip}</div>
                  {!organization.addressId && '-'}
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Website
                  </RadBox>
                  <div>{organization.website != null ? <RadLink href={organization.website}>{organization.website}</RadLink> : '-'}</div>
                </div>
                {/* <div>
                  <RadBox variant='awsui-key-label'>
                    Contacts
                  </RadBox>
                  <div>{organization.contacts.length > 0
                    ? organization.contacts.map((x) => <div key={x.id}><RadLink href={`/person/${x.person.id}`}>{x.person.fullName}</RadLink></div>)
                    : '-'}
                  </div>
                </div>
                 */}
                <div>
                  <RadBox variant='awsui-key-label'>
                    People
                  </RadBox>
                  <div>{organization.people.length > 0
                    ? organization.people.map((x) => <div key={x.id}><RadLink href={`/person/${x.person.id}`}>{x.person.fullName}</RadLink>{x.title != null ? ' - ' + x.title : ''}{x.primaryEmployer ? ' - Primary Employer' : ''}</div>)
                    : '-'}
                  </div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Insights
                  </RadBox>
                  <div>{organization.insights.length > 0
                    ? organization.insights.map((x) => <div key={x.id}><RadLink href={`/insight/${x.insight.id}`}>{x.insight.name}</RadLink></div>)
                    : '-'}
                  </div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Sectors
                  </RadBox>
                  <div>
                    <RadBadgeGroup
                      parentKey={organizationId}
                      items={organization.sectors}
                      mapFunc={x => ({ key: x.id, name: x.sector.name })}
                    />
                  </div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Tags
                  </RadBox>
                  <div>
                    <RadBadgeGroup
                      parentKey={organizationId}
                      items={organization.tags}
                      mapFunc={x => ({ key: x.id, name: x.tag.name })}
                    />
                  </div>
                </div>
              </RadColumnLayout>
            </RadContainer>
          </RadSpaceBetween>
        }
      />
    )
  }
}
